@import '../../imports';
@import '../../abstracts/checkboxes';

.createCampaign {
  @include respond-to-max('desktop-small') {
    margin-top: 4rem;
  }
  box-shadow: $shadow-light;

  &ListContainer {
    @include respond-to-max('desktop-small') {
      max-width: 100%;
    }
    margin: 0 auto;

    [class*='Input_error'] {
      bottom: -.2rem;
    }
  }

  &Container {
    width: 100%;
  }

  &ImagePreview {
    max-width: 25rem;
    max-height: 17.5rem;
    overflow: hidden;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  &Header {
    padding: 0;
    margin: 0;
    h4 {
      text-align: center;
      color: $default-color;
    }
  }

  &Recipients {
    &Container {
      @include size($max-height: 33vh, $min-height: 40rem, $max-width: 100%);
    }

    &Wrapper {
      min-width: 50rem;
    }
  }

  &Title {
    @include font($font-size: 2.8rem, $color: $gray);
  }

  &RemoveImage {
    @include position(absolute, 1rem, 1rem);
    @include font($font-size: 2rem, $color: $danger-color);
    z-index: 40;
    border-radius: 50%;
  }

  &WindowIcon {
    color: $gray;
    position: absolute;
    font-size: 5rem;
    transition: transform .3s;
    margin-right: 1rem;
    z-index: 1;
  }

  &Upload {
    position: relative;
    text-align: center;

    & > div {
      position: relative;
      display: inline-block;
    }

    input {
      @include size(0.01rem, 0.01rem);
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      @include display($display: flex, $justify-content: center, $align-items: center);
      background: $gray-light-accent-color;
      padding: .5rem 1rem;
      border-radius: .5rem;
      cursor: pointer;
      transition: background-color .2s;
      position: relative;
      height: 12rem;
      width: 25rem;
      margin: 0 auto;

      p {
        @include font($font-size: 1.6rem, $color: $default-color, $font-weight: map_get($font-weights, 'bold'));
        z-index: 2;
      }
    }


    &Cover {
      & > div {
        max-width: 100%;
      }

      label {
        max-width: 100%;
        width: 40rem;
      }
    }
  }

  &Textarea {
    @include respond-to-max('tablet-portrait') {
      height: 15rem;
    }
    @include respond-to-max('mobile-large') {
      height: 10rem;
      font-size: .9rem;
      padding: 1rem;
    }
    @include respond-to-max('mobile-medium') {
      height: 6rem;
    }
    @include size($width: 100%, $height: 10rem);
    margin: 1rem 0;
    border: .1rem solid $gray-light-accent-color;
    border-radius: 1rem;
    font-size: 1.3rem;
    resize: none;
    padding: 2rem;

    &Cover {
      @include size($width: 100%, $height: 20rem);
    }
  }

  &Input {
    font-size: 1.3rem;
    width: 100%;
    border: .1rem solid $gray-light-accent-color;
    padding: 1rem;
    margin: 1rem 0;
    @include respond-to-max('mobile-large') {
      align-self: center;
    }
  }
}


.createList {
  &Container {
    margin: 0 auto;
  }

  &InputContainer {
    display: flex;
    margin-bottom: 3rem;
  }

  &Input {
    @extend .createCampaignInput;
    max-width: 30rem;
    margin: 0 auto;
  }

  &RadioElement {
    &:before {
      @extend .radio;
      top: 1.2rem;
      left: -3rem;
    }

    &Selected:after{
      @extend .checked;
      top: 1.67rem;
      left: -2.45rem;
    }
  }
}

.linkBack {
  align-self: center;
}

.searchWrapper {
  max-width: 35rem;
  margin: 0 auto;
  input {
    padding-right: 3.5rem;
  }

  position: relative;
  height: 4rem;
  align-self: center;

  svg {
    position: absolute;
    color: $primary-color;
    right: 3.5rem;
    top: 1rem;
  }
}
