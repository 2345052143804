@import '../../imports';

.card {
  &TabList {
    font-size: initial;
    padding-top: 2rem;
  }
  &Template {
    display: grid;
    grid-auto-rows: 11% 89%;
    grid-auto-columns: 100%;
    min-height: 400px;
    column-gap: 20px;
    height: 100vh;
    &Convert{
      //max-height: 85vh;
      width: 100%;
      height: 87vh !important;
      grid-auto-rows: 7% 93%;
    }

    :global {
      .rdw-option-wrapper {
        border: none;
        background: transparent;
      }

      .rdw-option-active {
        background: $default-color;
      }

      .rdw-image-modal-btn,
      .rdw-link-modal-btn {
        width: 43%;
        padding: 0;
      }

      .rdw-link-modal-buttonsection,
      .rdw-image-modal-btn-section {
        width: 100%;
      }

      .rdw-dropdown-optionwrapper {
        z-index: 10;
      }
    }

  }
  &Filter {
    &Row1{
      width: 100%;
      display: grid !important;
      grid-template-columns: 27% 27% 28% 16%;
      column-gap: 10px;
      &Convert{
        display: grid !important;
        grid-template-columns: 27% 27% 28% 16% !important;
        row-gap: 10px;
        column-gap: 10px !important;
      }
      &BtnDeleteAll{
        button{
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }
      }
    }
    &Row2{
      width: 100%;
      display: grid !important;
      grid-template-columns: 43% 22% 22% 11%;
      column-gap: 15px;
      margin-top: 10px;
    }
    width: 100%;
  }

  &Content {
    width: 100%;
    display: grid;
    // grid-template-rows: 25% 25% 25% 25%;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 33.33% 33.33% 33.33%;
    // grid-column-gap: 10px;
    // grid-row-gap: 10px;
    height: 84vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;

    &Convert{
      height: auto !important;
    }

    img {
      max-width: 100%;
      max-height: 185px;
    }
    

    h1 {
      @include respond-to-max('mobile-large') {
        @include font($font-size: 5rem, $line-height: 6rem);
      }
      @include respond-to-max('mobile-medium') {
        @include font($font-size: 4.2rem, $line-height: 5rem);
      }
      @include font($font-size: 6rem, $line-height: 7rem);
    }

    h2 {
      @include respond-to-max('mobile-large') {
        @include font($font-size: 4rem, $line-height: 5rem);
      }
      @include respond-to-max('mobile-medium') {
        @include font($font-size: 3.6rem, $line-height: 4.2rem);
      }
      @include font($font-size: 4.6rem, $line-height: 5.6rem);
    }

    h3 {
      @include font($font-size: 3.2rem, $line-height: 3.6rem);
    }

    h4 {
      @include font($font-size: 2.4rem, $line-height: 3.2rem);
    }

    h5 {
      @include font($font-size: 1.6rem, $line-height: 1.3rem);
      margin: 0;
      text-align: center;
    }

    h6 {
      @include font($font-size: 1.6rem, $line-height: 2rem);
    }
    &Height{
      height: 100vh;
    } 
  }
  &Container{
    height: 100vh;
    overflow: hidden;
  }
  &Convert{
    display: flex;
    justify-content: center;
    width: 650px;
  } 
  &ElemConvert{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;
    // grid-row-gap: 15px;
    max-height: 170px;
    width: fit-content;
    justify-content: center;

    img{
      max-height: 15vh !important;
    }
  }
}
.cardContent.cardContentConvert img{
  max-height: 100px !important;
}

div[disabled]{
  pointer-events: none;
  opacity: 0.7;
}
.searchBar{
  height: 35.6px;
  border-radius: 5px;
  border: 1.5px solid #cbcbcb;
  margin-left: 20px;
  &Convert{
    height: 35px;
    border-radius: 5px;
    border: 1.5px solid #cbcbcb;
    margin-left: 0px;
  }
}
.btnSearch{
  position: relative;
  left: -20px;
  top: 8.5px;
  color: $secondary-color;
  cursor: pointer;
}
.btnConvert{
  position: relative;
  top: 8.5px;
  color: #78bb7bcf;
  cursor: pointer;
  left: -30px !important;
}
label{
  text-align: end;
  margin-top: 7px;
}
.blockFilterCountryConvert label{
  text-align: start;
}
.blockFilter{
  display: grid;
  column-gap: 10px;
  &Country{
    grid-template-columns: 15% 80%;
    &Convert{
      grid-template-columns: 20% 80% !important;
    }
  }
  &Cat{
    grid-template-columns: 20% 80%;
    &Convert{
      grid-template-columns: 20% 80% !important;
      grid-template-columns: 20% 78% !important;
      column-gap: 15px;
    }
  }
  &Brand{
    grid-template-columns: 18% 81% 1%;
    grid-column-gap: 0;
    &Convert{
      grid-template-columns: 20% 79% 1% !important;
    }
  }
  &All{
    grid-template-columns:  70% 10% 20%;
    align-items: flex-start;
    grid-column-gap: 3px;
    // display: flex;
    justify-content: center;
  }
}
.blockFilterAll input{
  margin-top: 11px;
}
.cardElem{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 10% 7% 7% 26%;
  grid-row-gap: 5px;
  height: 100%;
  align-items: end;
  justify-content: center;
}
.cardElem div[class*="postMediaContainer"]{
  height: 100%;
  margin-top: 0 !important;
  justify-content: center;
  display: flex;
}
.cardElem h5[class*="card-title"]{
  text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: clip;
    white-space: nowrap;
    height: 100%;
}
.cardCountryText{
  text-align: center;
}
.cardCountryTextConvert{
  text-align: center;
  font-size: 1.2rem;
}
.cardDenominationsText{
  text-align: center;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: center;    
}

.cardDenominationsTextConvert{
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  white-space: pre-line;
}
.active{
  border: 2px solid #78bb7bcf !important;
  border-radius: 10px !important;
  padding: 2rem !important;
}
.card{
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  padding: 1.5rem;
  cursor: pointer;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  max-height: 256px;
  padding-top: 5px;
}
button{
  cursor: pointer;
}
.customMultiselect > *:first-child:not(.singleSelect){
  height: 36px;
  overflow-y: scroll !important;
  border: 1px solid hsl(0,0%,80%) !important;
}
.customMultiselect > *:first-child input{
  padding-top: 3px !important;
  margin-top: 0px !important;
}
.limitWidthMultiSelect > *:first-child{
  width: 36.5rem !important;
}
.limitWidthMultiSelect > *:nth-child(2){
  width: 36.5rem !important;
}
.limitWidthMultiSelect{
  display: flex;
  /* width: 39.5rem !important; */
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin: 0 auto;
  padding-left: 0 !important;
  margin-bottom: 1.5rem;
}
// #multiselectContainerReact:first-child{
//   height: 36px;
//   overflow-y: auto !important;
// }

.padding{
  &Left{
    &5{
      padding-left: 5px;
      &Convert{
        padding-left: 0px !important;
      }
    }
    &20{
      padding-left: 20px;
    }
  }
}
.margin{
  &Top{
    &10{
      margin-top: 10px;
    }
    &0{
      margin-top: 0;
    }
    &Rem1{
      margin-top: 1rem;
    }
    &Imp0{
      margin-top: 0 !important;
    }
  }
  &Bottom{
    &0{
      margin-bottom: 0px;
    }
    &10{
      margin-bottom: 10px;
    }
    &15{
      margin-bottom: 15px;
    }
  }
}
.inputBlock label{
  text-align: start !important;
}
.customTextInput input{
  padding: 1rem 1.5rem 0.1rem 1.5rem !important;
}
.customTextInputBcd input{
  padding: 4rem 1.5rem 0.1rem 1.5rem !important;
}
.customTextInput label{
  top: -1.7rem !important;
  // color: #3e216b;
  font-weight: 600;
  font-size: 1.3rem;
}
.customMultiselect > *:first-child span[class="icon_down_dir"] {
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  border-left: 1px solid hsl(0,0%,80%);
  padding-left: 10px;
  margin-right: 10px;
  /* margin-top: 5px; */
  /* margin-bottom: 0px; */
  height: 20px;
}

.customMultiselect > *:first-child input {
  width: 100%;
}

.customMultiselect > *:first-child span[class="icon_down_dir"]:before {
  content: "\f107"  !important;
  font-size: 1.7rem;
    /* margin-bottom: 14px; */
  color: hsl(0,0%,80%);
}
/*
* Modal Css
*/
.customModal{
  height: 100vh;
  max-height: 98vh;  
}
.customEcardModal{
  max-width: 90% !important;
  max-height: 95vh !important;
}
.customEcardModal>div{
  width: 100% !important;
  height: 100vh !important;
  padding: 0;
}
/*
* Heighlight color
*/
.customMultiselect > *:first-child span[class*="chip"]{
  background: #91c894 !important;
  color: white;
  cursor: pointer;
}
.customMultiselect > *:last-child li:hover{
  background: #91c894 !important;
  color: #fff;
  cursor: pointer;
}
.customMultiselect > *:last-child li[class*="highlightOption"]{
  background: #91c894 !important;
  color: #fff;
  cursor: pointer;
}
.iconTitle{
  display: inline-block;
}
.customValidateModal, .customValidateModal>div{
  width: 100%;
}
.customValidateModal div[class*="mediaModalContent"]{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  row-gap: 0.4rem;
}
button[class*="vfrc-launcher"] > *:first-child, .c-bQoszf img{
  width: 12.5rem !important;
  height: 12.5rem !important;
}
.bdcDemand{
  min-height: 85vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 2rem;
}
.bdcDemandInput{
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 2rem;
  font-size: 2rem;
}
