@import '../imports';
@import '../utils/constants';

.nav {
  @include size($width: 100%, $max-width: 100%);
  @extend .verticalCenter;
  position: fixed;
  padding: 0 7rem;
  z-index: 11;
  transition: all 0.3s;
  background: $default-color;

  a {
    @include desktopSmall {
      font-size: 1.6rem;
    }
    @include prefix(transition, all 0s ease, webkit mos ms o);
    cursor: pointer;
  }

  &Scrolled {
    padding: 0 7rem;
    background: $default-color;
  }

  @include desktopMedium {
    display: flex;
    padding: 0 1rem;

    &Scrolled {
      padding: 0 1rem;
      background: $default-color;
    }

    & > a {
      @include display($display: flex, $align-items: center);
      display: inline-block;
    }
  }

  @include mobileLarge {
    padding: 0 1.5rem;
  }
}

.logo {
  @include desktopSmall {
    padding-right: 2rem;
  }
  @include tabletLandscape {
    padding-right: 0;
  }
  width: 10rem;
  padding-top: 1rem;
}

.menu {
  @include tabletLandscape {
    flex-direction: column;
    padding-top: 3rem;

    li {
      margin-bottom: 1.5rem;
    }
  }
  @include font($color: $forth-color, $font-size: 1.8rem, $font-weight: 300);
  @include prefix(transition, all 0.5s linear, webkit mos ms o);
  @extend .totalCenter;
  flex-grow: 2;
  margin-top: 0.3rem;

  @include desktopMedium {
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }

  li {
    padding: 0 2.5rem;

    @include desktopMedium {
      padding: 0 2rem;
    }

    @include desktopSmall {
      padding: 0 1rem;
    }

    @include mobileLarge {
      padding: 0 1.5rem;
    }
  }

  a::after {
    @include size($width: 0, $height: 0.3rem);
    @include prefix(transition, all 0.2s linear, webkit moz ms o);
    background: $gradient;
    display: block;
    content: '';
    margin-top: .4rem;
  }

  :global {
    .active {
      font-weight: $bold;

      &::after {
        width: 100%;
      }
    }
  }
}

.menu,
.userActions {
  @include mobileLarge {
    font-size: 1.6rem;
    display: flex;
    flex-wrap: wrap;
  }
}

.languageSwitcherOnboarding {
  color: $default-color !important;
}

.navLight {
  .menu,
  .userActions {
    color: $forth-color;
  }
}

.userActions {
  @include font($color: $forth-color, $font-size: 1.6rem, $font-weight: $regular);
  @extend .verticalCenter;
  margin-left: auto;
  order: 3;
  min-width: 38rem;

  @include desktopMedium {
    @include display(flex, space-evenly, center);
    min-width: 32%;
    float: right;

    li {
      display: inline-block;
    }
  }

  @include tabletLandscape {
    float: none;
    flex-direction: column;
    min-width: 100%;
    padding-top: 3rem;

    li {
      margin-bottom: 1.5rem;
    }
  }

  @include mobileLarge {
    font-size: 1.4rem;
  }
}

.signUp {
  margin-left: 2.5rem;

  @include mobileLarge {
    margin-left: 1.5rem;
  }
}

.lswitch {
  svg {
    fill: $primary-color;
  }

  & > div:first-of-type {
    text-align: right;
  }

  & > div {
    cursor: pointer;
  }
}

.hidePricing {
  .userActions {
    li:last-child {
      display: none;
    }
  }
}

.video {
  display: none;
}
