@import '../../imports';
@import '../../abstracts/checkboxes';

.cube {
  &SecondTitle {
    font-size: 1.4rem;
    padding-left: 3rem;
  }

  &TypeForms {
    &Wrapper {
      margin-top: 3rem;
    }
  }

  &Wrapper {
    padding-right: 12rem;
  }

  &Content {
    @include respond-to-max('mobile-large') {
      margin: 0;
      width: 85vw;
    }
    margin: 0 auto;
    width: 50vw;
  }

  &SectionWrapper {
    position: relative;
  }

  &FrequencyWrapper {
    @include respond-to-max('mobile-large') {
      padding-top: 4rem;
    }
  }

  &Subtitle {
    @include respond-to-max('mobile-large') {
      display: none;
    }
    @include font($font-size: 1.4rem, $font-weight: map_get($font-weights, 'bold'), $color: $secondary-color);
    padding-bottom: 3rem;
  }

  &Section {
    &Editable {
      opacity: 1;
      transition: opacity .3s;
    }

    &Disabled {
      opacity: .5;
      pointer-events: none;
      user-select: none;
    }

    &Title {
      @include respond-to-max('mobile-large') {
        padding-bottom: 3rem;
      }
      @include font($font-size: 1.3rem, $font-weight: map_get($font-weights, 'bold'));
      padding-bottom: 4rem;
    }

    &Subtitle {
      display: inline-block;
      font-size: 1.4rem;
      padding-bottom: 1.5rem;
    }
  }

  &ValidateButton {
    @include font($color: $primary-color, $font-size: 1.4rem, $font-weight: map_get($font-weights, 'bold'));
    @include display-flex($justify-content: flex-end);
    padding-top: .5rem;

    button {
      border: none;
      outline: none;
      font-weight: map_get($font-weights, 'bold')
    }

    & > span {
      cursor: pointer;
    }

    &:hover {
      svg {
        transform: scale(1.05);
      }
    }

    svg {
      transition: transform .2s;
      margin-right: .5rem;
    }

    &Selected {
      @include position(absolute, 0, 0);
      padding-top: 0;
    }
  }

  &ValidityDropdown {
    @include respond-to-max('mobile-large') {
      max-width: 100%;
    }
    max-width: 50%;
    margin-bottom: 3rem;
  }

  &RewardManagersInputGroup {
    @include respond-to-max('desktop-small') {
      flex-direction: column;
    }
    @include display-flex($align-items: center);

    input {
      flex: 1;
      margin-right: 3rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
  &Error {
    @include error;
  }
  &TypeForms {
    &Wrapper {
      margin-top: 3rem;
    }
  }

  &Section {
    &Editable {
      opacity: 1;
      transition: opacity 0.3s;
    }

    &Disabled {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }
  }

  .cube-type-form {
    cursor: pointer;

    &.cubeSectionDisabled {
      cursor: not-allowed;
      opacity: 0.5; // Grays out the option
      pointer-events: none; // Prevents user interaction
    }
  }
}

.goal {
  &List {
    display: flex;
    padding-bottom: 3.5rem;
  }

  &Item {
    @include respond-to-max('mobile-large') {
      min-width: 10rem;
    }
    background: $gray-lighter-color;
    color: $border-color;
    padding: .5rem 1.5rem;
    min-width: 16rem;
    box-shadow: $shadow;
    cursor: pointer;

    &:hover {
      &:before {
        background: $secondary-color;
        transform: scale(1.2);
      }
    }

    &:before {
      @include size($width: 1rem, $height: 1rem);
      content: '';
      border-radius: 50%;
      background: $border-color;
      display: inline-block;
      margin-right: 1rem;
      transition: all .2s;
    }

    &Active {
      background: $default-color;
      color: $secondary-color;
      cursor: default;

      &:before {
        background: $secondary-color;
      }
    }
  }

  &DeleteButton {
    padding-top: 1rem;
    background: transparent;
    border: none;
  }
}

.cubeRadio {
  @include respond-to-max('mobile-large') {
    display: flex;
  }

  &Item {
    font-size: 1.4rem;
    position: relative;
    margin-bottom: 3rem;
    margin-right: 3rem;
    padding-left: 3rem;
    cursor: pointer;
    display: inline-block;

    &:before {
      @extend .radio;
      top: -.2rem;
    }

    &Selected:after,
    &:hover:after {
      @extend .checked;
      top: .3rem;
    }
  }
}

.measurementType {
  &Wrapper {
    margin-top: 3rem;
    @include respond-to-max('mobile-large') {
      margin-top: 0;
      .cubeSectionSubtitle {
        display: none;
      }
      .cubeRadio {
        padding-top: 4rem;
      }
    }
  }
}

.allocationTypeRadioGroup {
  cursor: pointer;

  .cubeRadioItem {
    margin-top: 3rem;
    margin-bottom: 0;
  }
}

.simpleAllocation {
  @include respond-to-max('mobile-large') {
    form {
      width: 90%;
    }
  }
  margin-top: 3rem;
}

.disabledRadio{
  //color: #cdcdcd;
  // border: 0.1rem solid #cdcdcd;
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.4;
}