/**
 * Basic typography style for copy text
 */

//Default font properties to pe inherited in custom classes
.base-font {
  @include font($font-family: $primary-font, $line-height: 1.4);
}

.titles-base {
  @include font($font-family: $primary-font, $font-weight: map_get($font-weights, 'bold'));
  margin: 0;
}

body {
  @extend .base-font;
  font-size: 1.6rem;
}

.section {
  &Title {
    @extend .titles-base;
    @include font($font-size: 4.6rem, $line-height: 1.2);

    @include respond-to-max('mobile-large') {
      font-size: 3rem;
    }
  }

  &Subtitle {
    @extend .titles-base;
    @include font($font-size: 2.2rem, $line-height: 1.4);
  }
}

.heroTitle {
  @extend .titles-base;
  @include respond-to-max('mobile-large') {
    font-size: 3rem;
  }
  font-size: 5rem;
}

.lead {
  @extend .base-font;
  @include respond-to-max('tablet-portrait') {
    font-size: 1.6rem;
  }
  font-size: 2rem;

  &Lg {
    @extend .base-font;
    font-size: 3rem;
  }
}

.smallText {
  @extend .base-font;
  font-size: 1.4rem;
}

.lh1 {
  line-height: 1;
}

//generating classes for font sizes
@each $key, $value in $fontSizes {
  .text#{$key} {
    font-size: $value !important;
  }
}

//generating responsive classes for font sizes

//iterating on the breakpoints
@each $media, $breakpoint in $breakpoints-max-sizes {

  //create media query for all breakpoints
  @media (max-width: $breakpoint) {

    //generate the responsive classes
    @each $key, $value in $fontSizes {
      .#{$media}Text#{$key} {
        font-size: $value !important;
      }

      .#{$media}Mb#{$key} {
        margin-bottom: $value;
      }
    }

    .#{$media}WidthFull {
      width: 100% !important;
    }

    @each $key, $value in $position {
      .#{$media}#{$value} {
        position: #{$value} !important;
      }
    }
    //also you can add another each here in order to generate more responive classes
  }
}

@each $key, $value in $font-weights {
  .fontWeight#{$value} {
    font-weight: $value;
  }
}

.capitalize {
  text-transform: capitalize;
}

.text {
  &Uppercase {
    text-transform: uppercase;
  }

  &Lowercase {
    text-transform: lowercase;
  }

  &Decoration {
    &Underline {
      text-decoration: underline;
    }
  }
}

.verticalAlign {
  &Middle {
    vertical-align: middle;
  }
}
