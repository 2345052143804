@import "../../imports";

.bracket {
  &Wrapper {
    @include display-flex($align-items: center);
    @include respond-to-max('desktop-small') {
      @include display-flex($flex-direction: column, $align-items: stretch);
      text-align: left;
    }
    margin-bottom: 2rem;

    button {
      @include respond-to-max('desktop-small') {
        margin-top: 1.5rem;
      }
      border: none;
      padding: 0;
    }
  }

  &LabelWrapper {
    min-width: 10rem;
  }

  &Label {
    @include font($color: $default-text-color, $font-size: 1.4rem, $font-weight: map_get($font-weights, 'thin'));
  }

  &Disabled {
    opacity: .5;
    pointer-events: none;
    user-select: none;
  }

  &InputGroup {
    @include respond-to-max('desktop-small') {
      width: 100%;
      @include display-flex($justify-content: flex-start, $align-items: center);
      input {
        max-width:  65%;
      }
    }

    &Wrapper {
      @include respond-to-max('desktop-small') {
        margin: 0;
      }
      @include display($display: inline-flex, $flex-direction: column, $justify-content: center, $align-items: flex-start);
      position: relative;
      margin: 0 2rem;
    }

    input {
      width: 100%;
    }

    .bracketLabel {
      margin-left: .3rem;
    }
  }

  &Error {
    @include error;
    @include position(absolute, $bottom: -1.5rem, $left: 5.5rem);
    @include respond-to-max('desktop-small') {
      max-width: 65%;
      position: static;
      transform: initial;
      white-space: unset;
    }
    max-width: 11rem;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
  }
}

.inputHasError {
  input {
    border-color: $danger-color;
  }
}
