@import '../utils/constants';
@import '../imports';

$sidebarSize: 12.5rem;

.leftNav {
  display: flex;
  min-height: 100vh;
}

.basicContainer {
  padding: 14rem 4rem 0 17rem;

  @include mobileLarge {
    padding: 10rem 2rem 0 2rem;
  }

  @include mobileMedium {
    padding-top: 5rem;
  }
}

.centerContainer {
  form {
    @extend .totalCenter;
    @extend .resetToDirectionColumn;
  }
}

.navigation {
  @include tabletLandscape {
    @include prefix(transition, all .2s cubic-bezier(0, .32, .7, .85));
    @include prefix(transform, translateX(-100%));
    padding-top: 6rem;
    text-align: center;
  }
  @include position(fixed, 0, null, null, 0);
  @include size($width: $sidebarSize, $height: 100vh);
  z-index: 20;
  padding-top: 2rem;
  padding-bottom: 4rem;

  .logoImg {
    @include size($width: 100%, $max-width: 6.5rem, $max-height: 8rem);
    object-fit: contain;
  }

  .logOutBottom {
    @include position(fixed, null, null, 0, 0);
    width: 12.5rem;
    padding-bottom: 4rem;
  }

  @include mobileMedium {
    width: 16%;
  }

  @include mobileSmall {
    width: 20%;
  }

  a {
    @include tabletLandscape {
      width: auto;
      display: inline-block;
    }
    @extend .totalCenter;
  }

  @media all and (-ms-high-contrast: none) {
    height: 100% !important;
  }
}

.primary {
  .navigation {
    @include mobileLarge {
      @include size($width: 100%, $height: auto);
      padding: .5rem 1.5rem;
      text-align: left;

      a {
        display: inline-block;
      }
    }
    background: $third-color;
  }
}

.secondary {
  .navigation {
    @include mobileLarge {
      @include size($width: 100%, $height: auto);
      padding: .5rem 1.5rem;
      text-align: left;

      a {
        display: inline-block;
      }
    }
    background: $sidebar-menu-gradient;
  }
}

.wall {
  @include tabletLandscape {
    padding-left: 0;
  }
  padding-left: $sidebarSize;

  .navigation {
    background: $sidebar-menu-gradient;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .logoImg {
      @include tabletLandscape {
        @include position(absolute, 1.5rem, 1.5rem);

        &Onboarding {
          top: 8rem;
          left: 50%;
          right: unset;
          transform: translateX(-50%);
          object-fit: contain;
        }
      }
    }
  }

  & > div:first-child {
    overflow: auto;
    margin-bottom: 5rem;
    height: 100%;

    @include mobileLarge {
      padding-bottom: 4rem;
    }
  }
}

.wall-wrapper {
  @include display($display: flex, $flex-direction: column);
  width: 100%;
}

.userWrapper {
  @include font($color: $forth-color, $font-size: 1.4rem, $font-weight: $bold, $text-align: center);
  @include prefix(transition, all 0.3s, webkit moz ms o);
  @include prefix(transform, scale(0), webkit moz ms o);
  padding: 0 0.5rem;
  opacity: 0;

  &Loaded {
    @include prefix(transform, scale(1), webkit moz ms o);
    opacity: 1;
  }
  @include mobileMedium {
    font-size: 1.2rem;
  }
}

.icon {
  @include tabletLandscape {
    @include size($width: 5rem, $height: 5rem);
    margin-top: 1.5rem;
  }

  @include size($width: 4.9rem, $height: 4.9rem);
  border: 0.2rem solid $forth-color;
  border-radius: 4rem;
  position: relative;
  margin: 3rem auto 0.7rem;
  overflow: hidden;
  box-shadow: $light-shadow;

  &Company {
    border-radius: 30px;
    border: none;
    min-width: 4.9rem;
    min-height: 4.9rem;
  }

  img {
    @include force_center(absolute);
    width: 100%;
    min-width: 5rem;
  }
}


.nav {
  &Toggle {
    display: none;

    &Icon {
      @include tabletLandscape {
        display: block;
      }
      @include size($width: 3rem, $height: 2rem);
      @include position(fixed, 2rem, null, null, 2rem);
      display: none;
      z-index: 50;

      &Right {
        top: 3rem;
        left: auto;
        right: 1.5rem;

        .navToggleIconBurger {
          background-color: $forth-color;

          &::after,
          &::before {
            background-color: inherit;
          }
        }
      }

      &Burger {
        @include position(absolute, 50%, null, null, null);
        @include prefix(transform, translateY(-50%), moz webki ms o);
        @include size($width: 2.5rem, $height: .3rem);
        background-color: $secondary-color;
        border-radius: .5rem;

        &::after, &::before {
          @include prefix(transition, all .2s ease-in, webkit moz ms o);
          @include size($width: 100%, $height: .3rem);
          content: '';
          position: absolute;
          border-radius: .5rem;
          background-color: $secondary-color;
        }

        &::after {
          bottom: -.8rem;
        }

        &::before {
          top: -.8rem;
        }
      }
    }

    &Overlay {
      @include position(fixed, 0, 0, 0, 0);
      @include prefix(transition, all .3s ease);
      background-color: rgba($primary-light-color, .4);
      visibility: hidden;
      opacity: 0;
      z-index: 10;
    }

    @include tabletLandscape {
      &:checked {
        & ~ .navigation {
          @include position(fixed, 0, 0, 0, 0);
          @include prefix(transform, translateX(0));
          width: 100%;
          display: block;
        }

        & ~ .navToggleOverlay {
          opacity: 1;
          visibility: visible;
        }

        & ~ label {
          .navToggleIconBurger {
            background-color: transparent;

            &::after,
            &::before {
              background: $forth-color;
            }

            &::after {
              @include prefix(transform, rotate(-45deg), webkit moz ms o);
              bottom: 0;
            }

            &::before {
              @include prefix(transform, rotate(45deg), webkit moz ms o);
              top: 0;
            }
          }
        }
      }
    }
  }

  &Opened {
    overflow: hidden;
  }
}

.sidebarMenuIconRight {
  .navigation {
    width: 12.5rem;
  }

  @include mobileLarge {
    .navigation {
      @include display(flex, $justify-content: space-between);
      width: 100%;

      a {
        display: flex;
      }
    }

    .userWrapper {
      @include display(flex, $align-items: center);
      display: flex;
      align-items: center;
      text-align: left;
    }

    .icon {
      @include size($width: 4rem, $height: 4rem);
      margin: 0;
    }

    .userInfo {
      padding-left: 1rem;
    }
  }
}

.onboardingMobileMenu {
  @include tabletLandscape {
    display: flex !important;
  }
  @include position(fixed, 0, 0, null, 0);
  background-color: $forth-color;
  height: 7.5rem;
  z-index: 10;
  padding: 0 1rem;
  display: none !important;

  :global .language__dropdown-indicator {
    @include tabletLandscape {
      svg {
        fill: $default-color;
      }
    }
  }
}

/*Specific style for landing page*/
.landingNavCollapse {
  width: calc(100% - 12rem);
  display: flex;
  flex-wrap: wrap;

  @include tabletLandscape {
    @include position(absolute, 100%, 0, null, 0);
    background-color: $default-color;
    transform: translateX(-100%);
    transition: all .3s ease;
    width: 100%;
  }

  @include mobileLarge {
    max-height: calc(100vh - 8rem);
    overflow: auto;
  }
}

.navToggle:checked ~ .landingNavCollapse {
  @include tabletLandscape {
    transform: translateX(0);
    z-index: 99;
  }
}

/*Specific style for onboarding menu*/
.navToggleOnboarding {
  top: 2.75rem;
}
