@import '../imports';

.toast {
  &Container {
    box-shadow: $shadow-light;
    padding: 2rem;
    color: $default-text-color;
    word-wrap: break-word;

    & > div:first-of-type {
      width: 100%;
      padding-right: 1rem;
    }

    button {
      color: $primary-color;
    }
  }

  &ProgressBar {
    background: $primary-color;
  }
}
