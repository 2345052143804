@import "../../imports";

.selectType{

  &Container{
    @include display-flex($align-items:center);
  }

  &Element{
    @include display-flex($flex-direction: column,$justify-content: space-between,$align-items:center);
    @include size($min-height: 10rem,$width: 50%);
    cursor: pointer;
    padding: 2rem;
    margin: 3rem 1rem;

    & > p  {
      max-width: 100%;
      margin-bottom: 1rem;
    }

    &Active{
      color:$default-color;
      border-radius: 1.2rem;
      background-color: $primary-color;
      .selectTypeElementRadio{
        &::before{
          background-color: $primary-color;
        }
      }
    }
    &Radio{
      @include size($width:1.2rem,$height: 1.2rem);
      background-color: $default-color;
      position: relative;
      border-radius: 50%;
      box-shadow: $shadow-light;
      &::before{
        @include size($width: .6rem ,$height: .6rem);
        @include position($position: absolute,$left:0,$right:0, $top: 50%);
        content: "";
        border-radius: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
      }
      &:hover{
        &::before{
          background-color: $primary-color;
        }
      }
    }
  }
}
