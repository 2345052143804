@import '../imports';

.icon {
  svg {
    transition: all .2s ease-in-out;
  }

  &IsActive {
    svg {
      animation: animatePin .4s infinite;
    }
  }
}

@keyframes animatePin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
