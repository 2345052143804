@import '../../imports';
@import '../../utils/constants';

.navigationStyle {
  z-index: 20;
  overflow: auto; /* Enable scrolling */

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    display: none;
  }
   
  .wallLink {
    @include font($font-weight: 'bold');
  }
  
  .menuSeparator {
    max-width: 20rem;
    margin: 3rem auto;
    border-color: $forth-color;
  }

  ul {
    @extend .totalCenter;
    @extend .resetToDirectionColumn;
    @include desktopMedium {
      margin-top: 1.5rem;
    }
    margin-top: 7rem;
  }

  li {
    width: 100%;
    position: relative;

    &:hover svg {
      @include prefix(transform, scale(1.2), moz webkit o ms);
    }

    &:active {
      background: rgba($forth-color, .2);
    }

    a, div {
      @extend .totalCenter;
      @extend .resetToDirectionColumn;
      padding: 1.2rem 0;
      color: rgba($forth-color, .6);
      transition: color 0.2s;
      text-align: center;

      @include desktopMedium {
        padding: 0.6rem 0;
      }

      @include tabletLandscape {
        @include display($display: inline-flex, $flex-direction: row, $justify-content: flex-start);
        min-width: 15rem;
      }

      &.disabledElement {
        cursor: not-allowed;
        opacity: 0.3;
        &:hover div {
          visibility: visible;
        }
      }

      &.activeElement {
        color: $forth-color;
        opacity: 1;
      }

      svg {
        transition: all 0.3s;
        font-size: 2.5rem;

        @include desktopMedium {
          font-size: 1.6rem;
        }

        @include mobileLarge {
          font-size: 2rem;
        }
      }

      span {
        font-size: 1.5rem;
        padding-top: .5rem;

        @include tabletPortrait {
          font-size: 1.4rem;
          padding-top: .3rem;
        }

        @include tabletLandscape {
          @include font($font-size: 1.5rem, $text-align: left);
          min-width: 3.5rem;
        }
      }
    }

    &:hover {
      color: $forth-color;
    }
  }
}

.logoutButton {
  @include tabletPortrait {
    padding-bottom: 4rem;
  }

  @include mobileLarge {
    font-size: 1.1rem;
    margin-top: 3rem;
    padding-bottom: 6rem;
  }
  @extend .totalCenter;
  margin-top: 2rem;
  width: 100%;
  color: $forth-color;
  cursor: pointer;

  &:hover {
    svg {
      transform: translateX(-5px) rotateY(180deg);
    }
  }

  svg {
    font-size: 2.5rem;
    transform: rotateY(180deg);
    transition: transform 0.2s;
  }

  span {
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }
}

.onboardingContentWrapper {
  min-height: 100vh;
}

.divTooltip {
  
}

.elementTooltip {
  top: inherit !important;
  left: inherit !important;
}



