@import '../imports';
@import '../utils/constants';
@import '../common/box';

.block {
  @include prefix(transition, all 0.2s ease-in-out, webkit moz ms o);
  width: calc((100% - 4rem) / 3);
  margin-bottom: 7rem;

  @include mobileLarge {
    width: 50%;
  }

  @include mobileMedium {
    margin-bottom: 5rem;
    width: 100%;
  }
  &Wrapper {
    @include display($display: flex, $flex-wrap: wrap, $justify-content: space-between);
  }

  &Title {
    @include font($color: $menu-accent, $font-size: 2rem, $font-weight: $bold);
    margin-bottom: 3rem;

    &Large {
      font-size: 3rem;

      @include tabletLandscape {
        @include font($font-size: 2.4rem, $line-height: 2.4rem);
      }
    }
  }

  &Body {
    padding: 0 1.5rem 3rem;
  }

  &DescriptionWrapper {
    @include font($color: $default-color, $font-size: 2rem, $font-weight: $regular, $line-height: 2.6rem, $text-align: center);

    @include tabletLandscape {
      @include font($font-size: 1.4rem, $line-height: 1.7rem);
    }
  }

  &DescriptionImgWrapper {
    @include size($height: 16.1rem, $width: 100%);
    @include prefix(transition, all 0.2s ease-in-out, webkit moz ms o);
    background-size: cover;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    margin-top: auto;
    position: relative;

    img {
      @include force_center(absolute);
      max-width: 28rem;
    }
  }

  &Large {
    @include font($font-size: 2rem, $line-height: 2.3rem, $color: $primary-color);
    width: 85%;
    background: $forth-color;
    border: 0.5rem solid $secondary-color;
    border-radius: 1rem;
    padding: 3.6rem 4rem 4.3rem;

    .blockTitle {
      @include font($color: $default-color, $font-size: 2.1rem, $font-weight: $bold);
    }

    &.active {
      width: 100%;
    }
  }

  &WithShadow {
    @extend .boxWithShadow;
  }

  &Program {
    @include display($display: flex, $flex-direction: column);
    padding: 6rem 0 0;
    min-height: 39rem;

    @include mobileLarge {
      @include size($width: 100%, $max-width: 27.2rem, $min-height: 0);
      margin: 0 auto 4rem;
      padding-top: 4rem;
    }

    &Wrapper {
      max-width: 85.7rem;
      margin: 4rem auto 0;

      @include mobileLarge {
        flex-direction: column;
      }

      button {
        @include tabletLandscape {
          font-size: 1.4rem;
        }
      }
    }

    .blockTitle {
      text-align: center;
    }
  }

  &BtnWrapper {
    @extend .totalCenter;
    @extend .resetToDirectionColumn;
    height: 100%;
    z-index: 1;
    padding: 1rem 1.5rem;
    position: relative;

    button + button {
      margin-top: 0.8rem;
    }

    button {
      @include size($width: 100%, $max-width: 15rem);
      padding: 0 2rem;
      height: 3.2rem;
    }
  }
}

.small {
  @include font($font-size: 1.6rem, $line-height: 2rem);
  max-width: 24rem;
  margin: 0 auto;

  @include mobileLarge {
    font-size: 1.4rem;
  }
}

.large {
  font-size: 2rem;
  max-width: 45.4rem;
  margin: 1rem 1.5rem 2rem 0;

  .blockTitle {
    @include font($color: $forth-color, $font-weight: $bold, $font-size: 2rem);
    margin-bottom: 0.2rem;
  }

  @include desktopSmall {
    font-size: 1.8rem;
  }

  @include mobileLarge {
    font-size: 1.6rem;
  }
}

.hoverBlocks:hover {
  .block {
    opacity: 0.3;

    @include mobileLarge {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.fullWidthBlock {
  width: 100%;
  box-shadow: $full-shadow;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.hideLastBtn {
  .blockProgram:last-of-type .blockBtnWrapper {
    display: none;
  }

  .blockDescriptionWrapper {
    @include font($font-size: 1.4rem, $line-height: 1.8rem);
  }
}

.rewardsBlock{
  @include mobileLarge {
   margin-bottom: 3rem;
  }
  margin-bottom: 0;
  &.active{
    border: 0.1rem solid $secondary-color;
  }
  padding: 4rem 0;
  min-height: 36rem;
}

.blockElementIcon {
  margin: 0 auto 1rem;
  color: $secondary-color;
}

.blockElementTitle {
  @include font($font-size: 2.7rem, $text-align: center, $color: $secondary-color, $font-weight: 900);
  padding: 0 1.5rem;
  min-height: 7rem;
}

.blockElementDescription {
  font-size: 1.4rem;
  padding: 0 2.5rem;
  margin-bottom: 3rem;
}

.blockElementBody {
  text-align: center;
}

.blockElementButton{
  margin-top: auto;
  align-self: center;
}

.fourBlocks {
    max-width: 120rem;

    @include tabletPortrait {
      justify-content: space-evenly;
    }

  & .block {
    width: calc((100% - 4rem) / 4);

    @include tabletPortrait {
      width: calc((100% - 22rem) / 2);
    }

    @include mobileLarge {
      width: 50%;
    }

    @include mobileMedium {
      margin-bottom: 5rem;
      width: 100%;
    }
  }
}