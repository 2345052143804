@import '../../imports';

.wysiwig {
  &Toolbar {
    border: .1rem solid $border-dark-color;
    margin-bottom: 0;
    margin-top: 2rem;
    background: $wysiwig-color;

    :global {
      .rdw-option-wrapper {
        border: none;
        background: transparent;
      }

      .rdw-option-active {
        background: $default-color;
      }

      .rdw-image-modal-btn,
      .rdw-link-modal-btn {
        width: 43%;
        padding: 0;
      }

      .rdw-link-modal-buttonsection,
      .rdw-image-modal-btn-section {
        width: 100%;
      }

      .rdw-dropdown-optionwrapper {
        z-index: 10;
      }
    }
  }

  &Wrapper {
    width: 100%;
    margin-bottom: 3rem;
  }

  &Editor {
    word-break: break-all;
    border: .1rem solid $border-dark-color;
    border-top: none;
    padding: 1rem 2rem;
    min-height: 14rem;
    font-size: 1.4rem;

    img {
      max-width: 100%;
    }

    h1 {
      @include respond-to-max('mobile-large') {
        @include font($font-size: 5rem, $line-height: 6rem);
      }
      @include respond-to-max('mobile-medium') {
        @include font($font-size: 4.2rem, $line-height: 5rem);
      }
      @include font($font-size: 6rem, $line-height: 7rem);
    }

    h2 {
      @include respond-to-max('mobile-large') {
        @include font($font-size: 4rem, $line-height: 5rem);
      }
      @include respond-to-max('mobile-medium') {
        @include font($font-size: 3.6rem, $line-height: 4.2rem);
      }
      @include font($font-size: 4.6rem, $line-height: 5.6rem);
    }

    h3 {
      @include font($font-size: 3.2rem, $line-height: 3.6rem);
    }

    h4 {
      @include font($font-size: 2.4rem, $line-height: 3.2rem);
    }

    h5 {
      @include font($font-size: 1.8rem, $line-height: 2.2rem);
    }

    h6 {
      @include font($font-size: 1.6rem, $line-height: 2rem);
    }
  }
}
