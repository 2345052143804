@import '../imports';
@import '../utils/constants';

.block {
    @include font($font-size: 1.8rem, $font-weight: $light);
    margin-bottom: 2.4rem;
}

.center {
    text-align: center;
}

.bold {
    font-weight: $bold;
}

.underline {
    text-decoration: underline;
}

.link {
    cursor: pointer;
}

.general {
    &Label {
        @include font($font-size: 1.6rem, $color: $menu-accent, $font-weight: $bold);
        margin-bottom: 1rem;
    } 
    
    &Text {
        font-size: 1.4rem;
    } 
}

.defaultLabel {
    @include font($font-size: 1.6rem, $color: $default-color, $font-weight: $bold);
    margin-bottom: 1rem;
}
