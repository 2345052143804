@import '../../imports';

.metricsContainer {
  @include display-flex($flex-wrap: wrap);
  @include respond-to-max('desktop-small') {
    margin: 0 3rem;
  }
  @include respond-to-max('tablet-landscape') {
    padding-top: 10rem;
    margin: 0 1.5rem;
  }
  margin: 0 10rem;
}

.singleMetric {
  @include respond-to-max('desktop-small') {
    width: 23%;
  }
  @include respond-to-max('tablet-landscape') {
    width: 31%;
  }
  @include respond-to-max('mobile-large') {
    width: 48%;
  }
  @include respond-to-max('mobile-medium') {
    width: 98%;
  }
  @include size($height: 25rem, $width: 18%);
  @include display-flex($flex-direction: column, $align-items: center, $justify-content: center);
  text-align: center;
  margin: 1%;

  &:nth-child(-n+10) {
    .singleMetricValue {
      color: $light-green;
    }
  }

  &:nth-child(-n+5) {
    .singleMetricValue {
      color: $pink;
    }
  }

  &Title {
    @include display-flex($flex-direction: column, $align-items: center, $justify-content: center);
    font-size: 2rem;
    min-height: 5.6rem;
    padding-bottom: 2rem;
  }

  &ComingSoon {
    font-weight: map_get($font-weights, 'bold');
    color: $default-text-color;
    font-size: 2rem;
  }

  &Value {
    font-weight: map_get($font-weights, 'bold');
    color: $default-text-color;
    font-size: 3.5rem;
  }

  &List {
    text-align: left;
    text-transform: capitalize;

    li {
      position: relative;
      padding-bottom: 1rem;

      &:before {
        @include position(absolute, .2rem, null, null, -2rem);
        @include size($width: 1.5rem, $height: 1.5rem);
        content: '';
        display: inline-block;
        border-radius: 50%;
      }
    }
  }
}

.challenge:before {
  background: #00063A;
}

.sponsorship:before {
  background: $pink;
}

.loyalty:before {
  background: $light-green;
}

.freemium:before {
  background: $gray-light-accent-color;
}
