@import "../../imports";

.notificationsDropdown {

  &Wrapper {
    position: relative;
    margin: 0 1rem;
    z-index:300;
  }

  &New{
    color: $danger-color;
    padding-left: 1rem;
    &:before{
      content: "";
      background-color: $danger-color;
      width: .7rem;
      height: .7rem;
      border-radius: 50%;
      margin-right: .3rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &Backdrop {
    @include position($position: fixed, $top: 0, $left: 0);
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .4);
    z-index: 305;
  }

  &Icon {
    position: relative;

    svg {
      transition: all .4s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &Element {
    @include respond-to-max('mobile-large'){
      @include size($width: 100%, $max-width: 40rem, $min-height: 20rem, $min-width: 30rem);
      @include position($position: absolute, $right: -3rem, $top: 3rem);

    }
    @include size($width: 100%, $max-width: 40rem, $min-height: 20rem, $min-width: 40rem);
    @include position($position: absolute, $right: 0, $top: 3rem);
    box-shadow: $shadow-light;
    z-index: 2000;
    background-color: white;
    padding: 1rem;

    &Content {
      position: relative;
    }
  }

  &Item{
    word-wrap: break-word;
    margin: 1rem 0;
    padding: 1rem .5rem 0;
    @include display-flex();

    &Action{

    }

    &Image{
      @include size($width: 4rem, $height: 4rem);
      box-shadow: $shadow-light;
      border: .2rem solid $default-color;
      border-radius: 50%;
      background-color: $secondary-color;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 1rem;
    }
    &Content{
      font-size: 1.4rem;
      padding: 1rem 0 0;
      border-top: .1rem solid $gray-accent-color;
      @include display-flex($flex-direction: column);
      width: calc(100% - 5rem);
    }
    &Row{
      width: 100%;
      display: block;
    }
  }
}
