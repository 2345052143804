@import '../imports';
@import '../utils/constants';

.productsLoader {
  @include display(flex, center, center);
  height: 50vh;

  svg {
    color: $default-color;
  }
}

.productsWrapper {
  &SubmitBtn {
    @include display($display: flex, $justify-content: center, $align-items: center);
    margin-top: 1rem;
  }

  & > button {
    @include mobileLarge {
      margin-top: 5rem;
    }
    margin-top: 2rem;
  }

  :global {
    @include ie {
      .react-tabs__tab-panel {
        &:not(.react-tabs__tab-panel--selected) {
          width: 0;
        }
        width: 100%;
        display: none;
      }
      .react-tabs__tab-panel--selected {
        display: block;
      }
    }
  }
}

.tabContainer {
  @include tabletPortrait {
    flex-direction: column;
  }
  display: flex;
}

.tabList {
  @include display($display: flex, $flex-direction: column);
  @include tabletPortrait {
    margin-right: 0;
  }
  margin-right: 3rem;

  p {
    width: 100%;
  }
}

.tabItem {
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  @include size($width: 10rem, $height: 10rem);
  @include font($text-align: center, $font-size: 1.2rem);
  @include tabletPortrait {
    @include size($width: 100%, $height: 4rem);
    flex-direction: row;
    svg {
      display: none;
    }
  }
  cursor: pointer;
  padding: 1rem;

  & + .tabItem {
    @include tabletPortrait {
      margin-top: 0.4rem;
    }
    margin-top: 2.4rem;
  }

  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.tabItemAccent {
  border: 0.1rem solid $menu-accent;
  color: $menu-accent;

  &Selected {
    background: $menu-accent;
    color: $forth-color;
    border-color: $menu-accent;
  }
}

.tabItemRegular {
  border: 0.1rem solid $primary-color;
  color: $primary-color;

  &Selected {
    background: $primary-color;
    color: $forth-color;
    border-color: $primary-color;
  }
}
