@import '../imports';

.react-datepicker,
.react-datepicker__current-month {
  font-size: 1.1rem;
}

.react-datepicker__day {
  margin: 0.3rem;
}

.react-datepicker__header,
.react-datepicker__day--selected {
  background: $secondary-color;
  color:white !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  border-bottom-color: $secondary-color;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: $secondary-color;
}
.react-datepicker__day-name, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: white !important;
}
