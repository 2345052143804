@import '../imports';

.resellerFormModal {
  @include size($max-width: 99.8rem, $width: 100%, $max-height: 95vh, $height: 100%);
  @extend .totalCenter;
  background: $forth-color;
  padding: 0;
  margin: 2rem;
  position: relative;

  .closeBtn {
    right: 3rem;
  }
  @include mobileLarge {
    margin: 0;
    max-height: 100vh;
    border-radius: 0;
  }

  label[class^="inputLabel"],
  div[class*="Input_civility"] label {
    text-align: center;
  }

  form {
    @include size($width: 100%, $max-height: 90vh);
    overflow-y: auto;
    padding: 3rem 2rem 4rem 2rem;

    @include mobileLarge {
      padding-top: 5rem;
    }

    & > button:first-of-type {
      margin-top: 2rem;
      background: $third-color;
      border-color: $third-color;
      color: $forth-color;
    }

    & > div {
      max-width: 60rem;
      margin: 0 auto;

      input {
        max-width: 36.6rem;
      }
    }

    & > button {
      margin-top: 2rem;
      background: $third-color;
      border-color: $third-color;
      color: $forth-color;
      display: inline-block;
    }
  }

  & > div:first-of-type {
    width: 100%;
  }
}

.closeBtn {
  @include font($font-size: 3.2rem, $color: $third-color);
  @include position(null, 0, 3rem, null, null);
  font-size: 3.2rem;
  padding: 0;
  background: $forth-color;

  @include mobileLarge {
    top: 0;
  }
}

.closeBtnAlt {
  color: $secondary-color;
}
