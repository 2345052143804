// 1. Configuration and helpers
@import 'abstracts/abstracts-imports';

// 3. Base stuff
@import 'base/base-import';

// 4. Layout-related sections
@import
  'layout/sections',
  'layout/header.module',
  'layout/footer';

// 5. Components
@import
'components/button',
'components/card',
'components/chip';


span[class*="ag-icon-menu"]::before{
  color: white;
}
div[class*="ag-header-cell"]{  
  background: #18104d !important;
  color: white;
}