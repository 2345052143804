@import "../../imports";

.userDetails {
  @include size($max-width: 100%, $width: 100%);
  box-shadow: $shadow-light;
  overflow: hidden;
  background: $default-color;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  @include respond-to-max('tablet-landscape') {
    margin-top: 10rem;
  }

  &Informations {
    @include size($max-width: 80rem, $width: 95%);
    @include respond-to-max('mobile-large') {
      margin-left: 0;
    }
    margin-top: 4rem;
    margin-left: 7.5rem;

    &Row {
      @include display-flex($align-items: baseline);

      &Label {
        flex: .75;
        text-align: left;
      }

      &Input {
        padding-bottom: .75rem;
        flex: 1;

        input {
          font-size: 1.6rem;
        }
      }
    }
  }

  &Danger {
    color: $danger-color;
  }

  &Check {
    color: $primary-color;
  }

  &SmIcon {
    flex: 2 !important;
  }

  &Program {
    margin-top: 3rem;
    min-width: 50rem;

    &Title {
      font-size: 1.4rem;
      padding-bottom: .5rem;
    }
  }

  &Programs {
    @include size($max-width: 80rem, $width: 95%);
    @include respond-to-max('mobile-large') {
      margin-left: 0;
    }
    margin-bottom: 5rem;
    margin-top: 4rem;
    margin-left: 7.5rem;
    overflow-x: auto;

    &Header {
      @include font($color: $gray-accent-color, $font-weight: map_get($font-weights, 'bold'));
      @extend .flex-space-between;
    }

    &Row {
      @include font($color: $gray-light-accent-color, $font-size: 1.4rem);
      @extend .flex-space-between;
      margin: .75rem 0;

      &Element {
        @include display-flex();

        span {
          flex: 1.5;
        }

        svg {
          flex: 1;
        }
      }
    }

    p {
      @include size($min-width: 12.5rem, $max-width: 12.5rem);

      &:last-child {
        min-width: 3rem;
      }

      &:nth-last-child(2) {
        min-width: 18rem;
      }
    }

  }

}
