@import '../utils/mixins';
@import '../utils/variables';

.box {
  width: 33%;

  &WithShadow {
    box-shadow: $shadow;
    position: relative;
    border-radius: 1rem;
  }
}
