@import '../../imports';

.errorsDisplay {
  &Container {
    @include size($width: 50%, $max-width: 50rem);
    margin: 0 15rem;
    text-align: center;

    @include tabletLandscape {
      @include size($width: 100%, $max-width: initial);
      margin: 0;
    }
  }

  &Title {
    @include font($color: $danger, $font-size: 1.8rem);
    margin-bottom: 1rem;
  }

  &List {
    max-height: 60vh;
    overflow: auto;

    li {
      @include display(flex, space-between, $flex-wrap: wrap);
      font-size: 1.5rem;
      border-bottom: 0.1rem solid $primary-color;
      padding: 1.5rem 0.5rem;
      margin-right: 1rem;
      text-align: left;
      word-break: break-all;

      svg {
        cursor: help;

        @include mobileLarge {
          margin-top: 0.5rem;
        }
      }

      p {
        color: $danger;

        @include mobileLarge {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
