@import '../imports';
@import '../utils/constants';

.container {
  @include size($max-width: 60rem, $width: 100%);
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  align-items: flex-start;
  position: relative;
  padding: 0 1.5rem;
  min-height: 100vh;

  @include desktopSmall {
    padding: 0 1.5rem 0 1.5rem;
  }

  @include mobileLarge {
    padding-top: 5rem;
  }

  form {
    @include size($max-width: 36.4rem, $width: 100%);
    margin: 0 auto;

    & > button {
      @extend .totalCenter;
      @include size($height: 2.8rem, $min-width: 12rem);
      color: $forth-color;
      background: $gradient;
      border: none;
      margin-top: 7rem;
      margin-left: auto;
      display: flex;
      z-index: 100;
      position: relative;
    }

    svg {
      font-size: 1.8rem;
    }
  }

  svg {
    font-size: 1.8rem;
  }
}


.btnWrapper {
  @include size($max-width: 36.4rem, $width: 100%);
  margin: -2.8rem auto 0;
  position: relative;
}

.strenghtMeterWrapper {
  @include font($font-size: 1.8rem, $font-weight: 100);
  padding-left: 1.5rem;
  padding-top: 1rem;

  div + div {
    margin-left: -1.5rem;
  }
}

.onBoarding{
  .registerForm {
    &Error {
      @include position(absolute, null, 0, 5rem, 0);
    }
  }
}

.registerForm {
  &Error {
    @include font($color: $danger, $font-size: 1.2rem, $text-align: center);
    @include position(absolute, null, 0, 8rem, 0);
  }

  &Delete {
    button {
      background: none;
    }
  }
}
