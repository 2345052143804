@import '../../imports';
@import '../../utils/constants';

.avatarCreator {
  margin-top: 3.5rem;
  overflow: hidden;

  canvas {
    margin-bottom: 1rem;
    margin-top: -0.3rem;
  }
}

.buttonWrapper {
  @extend .resetToDirectionColumn;
  @include display(flex, center, center, $flex-direction: column);
  margin-top: 4rem;
}

button.closeBtn {
  color: $primary-color;
  border: none;
}

.sliderControl {
  margin-top: 2rem;
  display: flex;
  width: calc(100% - 1.2rem);
}

.sliderLabel {
  @include font($text-align: left, $font-weight: $bold);
  width: 9rem;
}
