@import "../../../imports";

.introBlock {
  &Banner {
    @include display-flex($justify-content: center, $align-items: center);
    height: 16rem;

    &Title {
      @include respond-to-max('mobile-large') {
        font-size: 2.5rem;
      }
      font-size: 5rem;
      padding: 0.5rem 4rem;
      user-select: none;
      position: relative;
      z-index: 1;
    }
  }

  &Body {
    @include respond-to-max('mobile-large') {
      padding: 3.5rem 1rem;
    }
    padding: 4rem 3rem;
    transition: all .5s;
    margin-bottom: 2rem;

    &Title {
      padding-bottom: 1.5rem;
      color: $primary-color;
    }
  }

  &Hidden {
    transition: all .5s;
    height: 6rem;
    padding: 0;
    overflow: hidden;
  }

  &Arrow {
    @include position(absolute, 1.5rem, 1.5rem);
    z-index: 3;
    font-size: 2.5rem;
    cursor: pointer;
    transition: all .3s;
    transform: rotate(180deg);

    &Reversed {
      transform: rotate(0deg);
      transform-origin: center;
    }
  }

  &WelcomeTitle {
    @include position(absolute, 1.8rem);
    @include font($color: $primary-color, $font-weight: map_get($font-weights, 'bold'));
    width: 100%;
    text-align: center;
    user-select: none;
  }
}

.socialAccount {
  font-size: 3rem;
  margin-right: 1.5rem;
  &Title {
    @include font($color: $gray, $font-weight: map_get($font-weights, 'bold'));
    padding: 2.5rem 0 1rem;
  }

  img {
    width: 2.5rem;
  }
}

.facebook {
  color: $facebook;
}

.twitter {
  color: $twitter;
}

.linkedin {
  color: $linkedIn;
}
