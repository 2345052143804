@import '../../imports';

.userRow {
  @extend .flex-direction-column;
  background-color: $default-color;

  &Space1 {
    @include size($min-width: 13rem);
  }

  &Space2 {
    @include size($min-width: 20rem);
  }

  &Wrapper {
    margin: 0 auto;
    overflow: auto;
    padding-bottom: 1rem;
    width: 100%;
  }

  &SeeUser {
    @extend .flex-center-total;
    @include size($width: 100%, $height: 100%);
    color: $primary-color-secondary-accent;

    &Wrapper {
      @include position(absolute, 0, 4rem);
      @include size($width: 0, $height: 100%);
      transition: .4s ease-in-out;
      background: $default-color;
      opacity: 0;
    }

    &Label {
      padding-left: 1rem;
      font-weight: map_get($font-weights, 'bold');
    }
  }

  &UserIcon {
    position: absolute;
  }

  &Element {
    @include display-flex();
    @include font($font-size: 1.3rem, $color: $gray-accent-color);
    padding: 2rem 0;
    width: 100%;
    transition: .2s ease-in-out;
    border-bottom: .1rem solid $gray-light-accent-color;
    position: relative;

    svg {
      transition: .2s ease-in-out;
      font-size: 1.3rem;
    }

    &Link {
      @include display-flex();
      padding: 0 2rem;

      a {
        @include isIE11 {
          word-break: break-all;
        }
      }
    }

    &:hover {
      box-shadow: $shadow-full-element;
      color: $gray;

      .userRowSeeUserWrapper {
        opacity: 1;
        width: 100%;
        right: 0;
      }

      svg {
        color: $primary-color-secondary-accent;
      }
    }
  }

  &Role {
    @include display-flex();
    flex: 0 0 13rem;

    &Element {
      & + & {
        padding-left: .5rem;
      }

      &Manager {
        font-weight: map_get($font-weights, 'bold');
      }
    }
  }

  &Status {
    @extend .flex-center-vertical;
    flex: 0 0 15rem;

    &Element {
      min-width: 5rem;

      & + & {
        padding-left: .5rem;
      }

      &Active {
        color: $primary-color-secondary-accent;
      }

      &Inactive,
      &Pending {
        color: $label-inactive;
      }

      &Blocked {
        color: $danger-color;
      }
    }
  }

  &ProgramRanking {
    max-height: 70rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.userManagerList {
  @include respond-to-max('tablet-landscape') {
    height: 15rem !important;
  }
}
