@import '../imports';
@import '../utils/constants';
@import '../common/checkbox';

.tab {
  &List {
    display: flex;
    margin-top: 4rem;
  }

  &Item {
    display: flex;
    position: relative;

    &:before {
      @extend .radio;
      cursor: pointer;
    }

    & + button {
      padding-left: 4rem;
    }

    &Selected:after,
    &:hover:after {
      @extend .checked;
    }
  }
}
