@import "../../imports";

.table{
  @include display-flex($flex-direction: column);
  @include size($width:100%,$max-width:100%);
  overflow-x: auto;


  &Container{
    min-width: 80rem;
    min-height: 25rem;
  }

  &Header{
    @include display-flex($flex-direction: row);
    @include font($color:$default-color,$font-weight: map_get($font-weights,'bold'));
    background-color: $secondary-color;
    width: 100%;
    padding: 2rem 3.5rem;
    &Element{
      flex: 1;
    }
  }

  &Row{
    border-bottom: .1rem solid $gray-light-accent-color ;
    &ContentWrapper{
      @include display-flex($flex-direction: row);
      padding: 2rem 3.5rem;
    }
    &Element{
      @include font($font-size:1.4rem, $color: $gray-accent-color);
      flex: 1;
      word-break: break-all;
    }
  }
}
