@import '../imports';
@import '../utils/constants';

.wrapper {
  @include size($height: 100vh, $width: 100%);
}

.tailored,
.launch {
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  @include size($height: 52%, $width: 100%);
  @include font(
    $font-size: 2.1rem,
    $font-family: $default-font,
    $font-weight: $bold,
    $text-align: center,
    $color: $forth-color
  );
  background: $third-color;
  padding: 0 1.5rem;

  img {
    margin-top: 2rem;
    width: 10rem;
  }
}

.launch {
  background: $gradient;
}

.activeCursor {
  cursor: pointer;
  font-weight: bold;
}

.navigationSection {
  @extend .verticalCenter;
  @extend .resetToDirectionColumn;
  @include font($font-size: 2rem, $font-family: $default-font, $font-weight: $regular, $text-align: center);
  padding: 9.6rem 1.5rem;
  width: 100%;

  button {
    margin-top: 4.5rem;
  }

  span {
    width: 100%;
  }

  @include mobileMedium {
    font-size: 1.8rem;
    padding: 6.6rem 1.5rem 0;
  }
}

.emailActivation {
  @include size($height: 100vh, $width: 100%, $min-height: 50rem);
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  @include font($font-size: 1.6rem, $line-height: 2.2rem, $font-weight: $regular, $text-align: center, $color: $forth-color);
  background: $logo-gradient;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;

  & > div {
    width: 100%;
  }

  button {
    margin-top: 1.5rem;
  }
}

.mainIcon {
  font-size: 8rem;
  margin-bottom: 5rem;
}

.logoImg {
  max-width: 10rem;
  margin-bottom: 5rem;
}

.welcomePageContainer {
  @include size($width: 100vw, $height: 100vh);
  @extend .totalCenter;
  flex-wrap: wrap;
  overflow: hidden;
  background: $gradient;
  text-align: center;
  padding: 0 1.5rem;

  img {
    display: block;
    margin-bottom: 2rem;
    max-width: 100%;

    @include mobileLarge {
      margin-bottom: 1rem;
    }
  }

  button {
    display: inline-block;
  }
}
