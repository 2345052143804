@import '../../../style/imports';
@import '../../../../sass-boilerplate/stylesheets/imports';

.products {
  &Controls {
    @include display(flex, $flex-direction: column, $align-items: center);
    padding-top: 4rem;

    button {
      @include mobileLarge {
        @include size($height: 4.5rem, $max-width: 32rem, $width: 100%, $min-width: initial);
      }
      margin-bottom: 2rem;
      min-width: 40rem;
    }
  }
}

.createProduct {
  &Wrapper {
    @include mobileLarge {
      @include display($flex-direction: column, $align-items: center);
    }
    @include display($display: flex, $align-items: flex-end);
  }

  &Box {
    @include size(40rem, 25rem);
    @include display($display: flex, $flex-direction: column, $align-items: center, $justify-content: space-between);
    @include tabletPortrait {
      @include size($width: auto, $min-height: 25rem);
      margin-top: 2rem;
    }
    background-position: center center;
    background-size: cover;
    padding: 2rem 1.5rem 3rem;
    border: 0.2rem dashed $border-light;
  }

  &Info {
    @include mobileLarge {
      padding-left: 0;
      padding-top: 2rem;
    }
    padding-left: 2rem;

    p {
      @include font($font-size: 1.2rem, $color: $primary-color);
    }
  }

  &Category {
    width: 100%
  }

  &ImageUpload {
    input {
      @include size(0.01rem, 0.01rem);
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      @include display($display: flex, $justify-content: center, $align-items: center);
      @include font($color: $border-dark, $font-size: 1.5rem);
      box-shadow: $light-shadow;
      background-color: rgba($forth-color, 0.7);
      padding: .5rem 1rem;
      border-radius: .5rem;
      cursor: pointer;
      transition: background-color .2s;

      &:hover {
        background-color: $forth-color;
      }

      svg {
        transition: transform .3s;
        margin-right: 1rem;
      }
    }
  }

  &Notice {
    @include font($color: $danger, $font-weight: $bold, $font-size: 1.4rem, $text-align: center);
    display: flex;

    &Error {
      color: $danger;
    }

    &Success {
      color: $accent-color
    }
  }
}

.createCategory {
  &Wrapper {
    @include display($display: flex, $flex-wrap: wrap, $justify-content: flex-start, $align-items: center);
    @include tabletPortrait {
      padding-top: 3rem;
      width: 100%;
    }
    position: relative;
    width: calc(100vw - 50rem);

    input {
      width: 40rem;
    }

    & > button {
      margin-top: 4rem;
    }

    .itemsScrollView {
      @include respond-to-max-height('tablet-landscape-height') {
        max-height: 43vh;
      }
      max-height: 41vh;
    }
  }

  &Notice {
    @include tabletLandscape {
      top: 3rem;
    }
    @include position(absolute, -2rem, null, null, 0);
  }

  &Controls {
    @include mobileLarge {
      @include display($flex-wrap: wrap, $justify-content: center);
      input {
        width: 100%;
      }
    }
    @include display($display: flex, $justify-content: flex-start, $align-items: center);
    width: auto;
    margin-bottom: 1.5rem;

    & > button {
      @include mobileLarge {
        margin-left: 0;
      }
      margin-left: 2rem;
    }

    &Submit {
      @include mobileLarge {
        margin: 0;
      }
      margin: 0 0 2rem 2rem;
    }
  }
}

.itemsScrollView {
  @include display($display: flex, $flex-wrap: wrap, $justify-content: space-around);
  @include size($width: 100%, $max-height: 35vh);
  overflow-y: auto;

  & > div {
    @include size(30rem, 22rem);
    @include display($display: flex, $flex-direction: column, $align-items: center, $justify-content: space-between);
    background-position: center center;
    background-size: cover;
    padding: 2rem 1.5rem 3rem;
    margin: 1rem;
    box-shadow: $full-shadow;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      background: $forth-color;
      opacity: 0.7;
      transition: opacity 0.2s;
      border-radius: 1rem;
      padding: 0 2rem;
      margin-top: 10rem;
      text-align: center;
    }
  }

  & > span {
    @include display($display: flex, $justify-content: center, $align-items: center);
    padding: 2rem 0;
    width: 100%;
  }
}

.addExistingProducts {
  &Wrapper {
    @include tabletPortrait {
      margin-top: 2rem;
      margin-bottom: 5rem;
      display: block;
    }
    height: 50vh;
    display: flex;
    flex-wrap: wrap;

    .addExistingCategory {
      &View {
        @include respond-to-max-height('tablet-landscape-height') {
          max-height: 52.5vh;
        }
        @include size($max-height: 50vh, $min-width: 50vw);
      }
    }
  }

  &View {
    @extend .itemsScrollView;
    @include respond-to-max-height('tablet-landscape-height') {
      max-height: 46.5vh;
    }
    @include size($max-height: 44vh, $min-width: 50vw);

    h3 {
      @include mobileLarge {
        line-height: 1.4rem;
        width: 8rem;
      }
      @include size($width: 13rem, $height: 1.5rem);
      padding-bottom: 1.6rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &Controls {
    @include tabletPortrait {
      margin-top: 2rem;
    }
    @include display($display: flex, $justify-content: center, $align-items: center, $flex-direction: row);
    color: $accent-color;
    padding: 0 2rem;
    cursor: pointer;

    &:hover {
      svg {
        transform: scale(1.1);
      }
    }

    &Wrapper {
      @include display($display: flex, $justify-content: center, $align-items: center);
      flex: 0 0 100%;
    }

    svg {
      display: block;
      font-size: 3.6rem;
      margin-bottom: 1rem;
      transition: transform 0.2s;
    }

    span {
      @include font($font-size: 1.5rem, $font-weight: $bold, $text-align: center);
      min-height: 5.4rem;
      user-select: none;
      padding: 1.2rem 0 0 1rem;
    }
  }
}

.withEllipsis {
  @include mobileLarge {
    width: 8rem;
  }
  @include size($width: 13rem, $height: 1.5rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.productsTitle {
  @include font($color: $primary-color, $font-size: 1.4rem, $text-align: center, $line-height: 1.4rem);
}

.productsCount {
  @include tabletPortrait {
    display: none;
  }
  @include font($color: $menu-accent, $font-size: 1.4rem, $font-weight: $bold);
  flex: 0 0 100%;
  padding-top: 2rem;
  min-height: 4rem;
}
