@import '../../imports';

.postTab {
  background-color: $default-color;
  @include respond-to-max(mobile-medium) {
    padding: 2.5rem;
  }
  @include size($width: 100%, $height: 100%);
  overflow: hidden;
  box-shadow: $shadow-light;
  border-radius: 0 0 1rem 1rem;
  padding: 2.2rem 2.5rem;

  &BaseBlock {
    @include size($width: 100%, $height: 7.5rem);
  }

  &List {
    @extend .flex-space-between;
    width: 100%;
    padding-bottom: 2rem;
    position: relative;
  }

  &Item {
    @include respond-to-max(mobile-medium) {
      font-size: 1.4rem;
    }
    width: 47%;
    font-size: 1.6rem;
    padding-bottom: 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  .postTabItemPost {
    .postLine {
      background: $secondary-color;
    }
  }

  .postTabItemTask {
    text-align: right;

    .postLine {
      right: 0;
      background: $primary-color;
    }
  }

  .postTabItemPost,
  .postTabItemTask {
    position: relative;

    .postLine {
      @include position($position: absolute, $bottom: 0);
      @include size($width: 100%, $height: .1rem, $max-width: 26.5rem);
      display: block;
      content: '';
    }
  }

  &BaseBlockOpen {
    height: auto;

    .postTabItem {
      color: $gray-light-accent-color;

      &:not(.postTabItemSelected) .postLine {
        background: $gray-light-accent-color;
      }

      &.postTabItemSelected .postLine {
        max-width: none;
      }
    }
  }

  &ItemSelected,
  &BaseBlockClosed .postTabItem {
    font-weight: map_get($font-weights, 'bold');

    &.postTabItemPost {
      color: $secondary-color;
    }

    &.postTabItemTask {
      color: $primary-color;
    }
  }

  &BaseBlockClosed {
    margin-bottom: 3rem;
  }
}

.postIcon::before {
  @include font(
          $font-weight: map_get($font-weights, 'bold'),
          $font-size: 1.5rem,
          $font-family: $font-awesome,
          $color: inherit
  );
  @include position(absolute, 1.3rem, -1.2rem);
  transition: all .2s ease-in-out;
  content: $pen-icon;
  opacity: 0;
}

.postTabItemSelected .postIcon::before {
  opacity: 1;
}

.postTabItemTask {
  .postIcon::before {
    right: initial;
    left: 0;
  }
}

.modifyProgram::before {  
  top: -13px;
  right: -5px;
  opacity: 1;
  color: #3e216b;
  position: relative;
  cursor: pointer;
}
.modifyContactBlock {
  // position: relative;
  // left: 105%;
  // top: -25%;
}
.modifyPayBlock {
  // position: relative;
  // left: 70%;
  // top: -3.5rem;
}