// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  @include on-event{box-shadow: $shadow-light};
  @include prefix(transition, all .3s, webkit moz ms o);
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  font-size: 1.6rem;
  min-height: 3rem;
  padding: .4rem 3.8rem;

  &Lg{
    @include size($max-width:17rem,$min-width: 17rem);
    padding-left: .3rem !important;
    padding-right: .3rem !important;
    font-size: 1.4rem !important;
  }

  &Center {
    @extend .flex-center-total;
    width: 100%;
    margin: 0 auto;
  }
}

.primary {
  @include buttonBase($default-color, $gradient);

  &inverted {
    @include buttonBase($primary-color, $default-color, .1rem solid $primary-color);
    @include on-event {
      @include buttonBase($default-color, $primary-color);
    }
  }
}

.secondary {
  @include buttonBase($default-color, $secondary-color);
  @include prefix(transition, all .3s, webkit moz ms o);

  &inverted {
    @include buttonBase($secondary-color, $default-color, .1rem solid $secondary-color);
  }
}

.danger {
  @include buttonBase($default-color, $danger-color);
}

.disabled {
  @include buttonBase($gray-light-accent-color, $default-color, .1rem solid $border-color);
  cursor: not-allowed !important;
}

.textOnly {
  @include buttonBase($gray, transparent);
  padding: .4rem 3.8rem;

  &inverted {
    color: $default-color;
    border: none;
  }
}
