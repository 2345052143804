// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/*
  added null property so you can get away using only what you need from this mixin
  ex: good: @include size($width: 100%, $max-widht: 320px);
  bad: @include size(100px, 200px); works anyhow but it's difficult to maintain
*/
@mixin size($height: null, $width: null, $min-width: null, $max-width: null, $min-height: null, $max-height: null) {
  height: $height;
  width: $width;
  min-width: $min-width;
  max-width: $max-width;
  min-height: $min-height;
  max-height: $max-height;
}

/*
  added null property so you can get away using only what you need from this mixin
  ex1: @include font($font-size: 10px, $line-height: 1em);
  ex2: @include font(10px, red,  center);
  you can use it without variables but you MUST keep the order
*/

@mixin font($font-size: null,
$color: null,
$text-align: null,
$line-height: null,
$font-weight: null,
$font-family: null,
$text-indent: null,
$white-space: null,
$letter-spacing: null,
$word-spacing: null,
$text-transform: null,
$text-decoration: null,
$font-style: null) {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
  text-decoration: $text-decoration;
  text-align: $text-align;
  text-indent: $text-indent;
  white-space: $white-space;
  word-spacing: $word-spacing;
}

/*
  mixin for setting the possition;
  if you don't want to set set/right/top/bottom you can use it like:
  @include position(absolute);
  .element { @include position(absolute, 10px, 20px, 30px, 40px); }
  this is if you want to define top, right bottom, left in this exact order
  .element { @include position(absolute, $top: 10px, left: 20px); }
  this is if you don't keep the order
*/
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/*
  fallback mixin for any vendor prefix:
    ex: @include prefix(transform, rotate(42deg), webkit moz ms o);
  you don't have to include all 4 fallbacks, you have to include at least 1;
*/
@mixin prefix($property, $value, $vendors: webkit moz ms o) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  #{$property}: #{$value};
}

/*
  mixin to vertically-align: center a selector;
    ex: .txt { @include vertical_center(absolute); }
  beware: gives position as param and adds top, left: 50%, and translates the container to center it.
*/
@mixin vertical_center($position){
  @include prefix(transform, translateY(-50%), webkit moz ms o);
  @include position($position, $top: 50%);
}

/*
  mixin to vertically-align: center and horizontal align: center a selector;
    ex: .txt { @include force_center(absolute); }
  beware: gives position as param and adds top, left: 50%, and translates the container to center it.
*/
@mixin force_center($position) {
  @include prefix(transform, translate(-50%, -50%), webkit moz ms o);
  @include position($position, $top: 50%, $left: 50%);
}

/*
  mixin to add a border-radius to element;
    ex: .txt { @include border-radius(20px); }
*/
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

/*
  fallback mixin for any vendor prefix:
    ex: @include background-prefix(background-image, linear-gradient, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12), webkit moz ms o);
  you don't have to include all 4 fallbacks, you have to include at least 1;
*/
@mixin background-prefix($property, $value, $color-start, $color-end, $vendors: webkit moz ms o) {
  @if $vendors {
    @each $vendor in $vendors {
      #{$property}: #{'-' + $vendor + '-' + $value + '(' + $color-start + ',' + $color-end + ')'};
    }
  }
  #{$property}: #{$value};
}

/*
  added null property so you can get away using only what you need from this mixin
  ex: good: @include display($display: flex, $justify-content: center, $align-items: center);
  bad: @include size(100px, 200px); works anyhow but it's difficult to maintain
*/
@mixin display-flex (
  $justify-content: null,
  $align-items: null,
  $align-self: null,
  $flex-direction: null,
  $flex-wrap: null,
  $flex-grow: null,
  $order: null
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  align-self: $align-self;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  flex-grow: $flex-grow;
  order: $order;
}

/*
  mixin to add error style to element
    ex: @include error(absolute, null, null, 0.8rem, 0, $text-align: center, $width: 100%);
*/
@mixin error($position: null, $top: null, $right: null, $bottom: null, $left: null, $display: null, $text-align: null, $width: null) {
  @include position($position, $top, $right, $bottom, $left);
  @include font($font-size: 1.2rem, $color: $danger-color, $text-align: $text-align);
  width: $width;
  display: $display;
}


/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin buttonBase($color: null, $background: null, $border: null) {
  background: $background;
  border: $border;
  color: $color;
}

@mixin border($border-width: null, $border-style: null,$border-color:null) {
  border-width: $border-width;
  border-style: $border-style;
  border-color: $border-color;
}

/*
  added null property so you can get away using only what you need from this mixin
  ex: good: @include display($display: flex, $justify-content: center, $align-items: center);
  bad: @include size(100px, 200px); works anyhow but it's difficult to maintain
*/
@mixin display(
  $display: null,
  $justify-content: null,
  $align-items: null,
  $align-self: null,
  $flex-direction: null,
  $flex-wrap: null,
  $flex-grow: null,
  $order: null
) {
  display: $display;
  justify-content: $justify-content;
  align-items: $align-items;
  align-self: $align-self;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  flex-grow: $flex-grow;
  order: $order;
}

@mixin border-top($border-width: null, $border-style: null, $border-color:null, $border-radius:null) {
  border-width: $border-width;
  border-style: $border-style;
  border-color: $border-color;
  border-radius: $border-radius;
}

.userBlockBase {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem;
}
