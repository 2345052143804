//Gray accent
$gray: #707070 !default;
$gray-accent-color: #868586 !default;
$grey-light-color: #DCDCDC !default;
$gray-light-accent-color: #cdcdcd !default;
$gray-lighter-color: #F5F5F5 !default;

//Primary colors
$primary-color: #EC407A !default;
// $primary-color-accent: #78bb7bca !default;
$primary-color-accent: #EC407A !default;
$primary-color-secondary-accent: #78bb7bc0 !default;
$primary-light-color: #dd73a2;

//Secondary colors
$secondary-color: #6134a5 !default;
$secondary-color-accent: #3f216be1 !default;
$secondary-color-light: #3f216be1 !default;
$wysiwig-color: #C2D4E6;

//third colors
$third-color: #78bb7bcf !default;
$third-color-accent: #78bb7bd5 !default;
$third-light-color: #78bb7ba7;

// Colors
$default-color: #000000;
// $primary-color: #78bb7bcf ;
$primary-light-color: #868586 ;
// $secondary-color: #EC407A;
// $third-color: #3e216b;
// $third-color: #3e216b;
$forth-color: #ffffff;
// $accent-color: #78bb7bcf;
$accent-color: #78bb7bcf;
$disabled-color: #eaeaea;
// $menu-accent: #46BD8F;
$menu-accent: #78bb7bcf;
$danger: #dd73a2;
$alert: #EC407A;
$secondary-accent: #1492E6;

$grey-light: #DCDCDC;
$border-light: #cdcdcd;
$border-medium: #EBEEEF;
$border-dark: #6B6B6B;
// $modalSuccessBackground: #70C684;
$modalSuccessBackground: #78bb7bcf;
$wysiwig-color: #C2D4E6;

$containerWidth: 1370px;

$default-font : 'Roboto', Arial;

$flag-icon: '\f024';
$star-icon: '\f005';
$prev-icon: '\f0d9';
$next-icon: '\f0da';
$file-icon: '\f15b';
$arrow-right-icon: '\f0da';
$close-icon: '\f00d';
