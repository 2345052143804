@import "../../imports";

.modalReset {
  height: auto;
  border-radius: 1rem;
  padding: 4rem 2rem;
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.modalOverlay {
  @include position(fixed, 0, 0, 0, 0);
  z-index: 9999 !important;
}

.modalPricing {
  margin-right: -3rem;
}
