@mixin desktopMediumUp {
  @media all and (min-width: $desktopMediumBreakpoint) {
    @content;
  }
}

@mixin desktopMedium {
  @media all and (max-width: $desktopMediumBreakpoint - 1px) {
    @content;
  }
}

@mixin desktopSmall {
  @media all and (max-width: $desktopSmallBreakpoint - 1px) {
    @content;
  }
}

@mixin desktopSmallUp {
  @media all and (min-width: $desktopSmallBreakpoint) {
    @content;
  }
}

@mixin tabletLandscape {
  @media all and (max-width: $tabletLandscapeBreakpoint) {
    @content;
  }
}

@mixin tabletPortrait {
  @media all and (max-width: $tabletPortraitBreakpoint) {
    @content;
  }
}

@mixin mobileLarge {
  @media all and (max-width: $mobileLargeBreakpoint) {
    @content;
  }
}

@mixin mobileMedium {
  @media all and (max-width: $mobileMediumBreakpoint) {
    @content;
  }
}

@mixin mobileSmall {
  @media all and (max-width: $mobileSmallBreakpoint) {
    @content;
  }
}

@mixin ie {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
