@import '../../imports';

.rewards {

  &CommitmentNote,
  &Info,
  &Icon {
    @include mobileLarge {
      margin-bottom: 2rem;
    }
    margin-bottom: 4rem;
  }

  &CommitmentNote {
    display: inline-block;
    font-style: italic;
  }

  &Icon {
    @include mobileLarge {
      font-size: 3rem;
    }
    @include font($color: $third-color, $font-size: 5rem);
  }

  &SubTitle {
    color: $secondary-color;
  }

  &Content {
    @include mobileLarge {
      font-size: 1.4rem;
    }
    @include font($font-family: $default-font, $font-size: 1.6rem, $line-height: 1.4, $text-align: center);
    max-width: 60rem;
    margin: 0 auto;
  }

  &Info {
    font-weight: $bold;
  }

  &Terms {
    @include font($font-size: 1.4rem, $text-decoration: underline);
    display: inline-block;
    margin-top: 2rem;

    &:hover {
      text-decoration: underline;
    }

  }
}
