// /* Common styles */
// .aiForm {
//   max-width: 800px; /* Increase the width to give more space */
//   margin: 0 auto;
//   padding: 20px 2000px;
// }

// .formGroup {
//   margin-bottom: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between; /* Ensure elements take full width */
//   flex-wrap: nowrap; /* Prevent line breaks */
// }

// .label {
//   flex: 0 1 250px; /* Fixed width for labels */
//   margin-right: 10px;
//   font-size: 18px; /* Increase the font size */
//   white-space: nowrap; /* Prevent line breaks */
//   text-align: left; /* Align text to the left */
// }

// .select,
// .inputFile {
//   flex: 1; /* Allow input to grow and fill space */
//   padding: 10px;
//   font-size: 16px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-sizing: border-box;
//   text-align: right; /* Align text to the right */
// }

// .submitBtn {
//   color: #fff;
//   padding: 10px 20px;
//   font-size: 16px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }

// .textIntro {
//   margin-left: 50px;
//   margin-right: 50px;
// }

// .aiDescription {
//   font-style: italic;
//   color: #888;
// }

// .multiContainer {
//   margin-left: 20%;
//   margin-right: 20%;
// }

// .optionBold {
//   font-weight: bold;
// }

// .aiFormPadding {
//   padding: 0 20px; /* Padding on left and right */
// }

// .aiSubmitText {
//   margin: 50px 50px; /* Margin on left and right */
//   padding: 10px 0;
//   font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
//   font-size: larger;
// }

// .uploadedFiles {
//   display: flex;
//   align-items: center;
//   margin-left: 10px; /* Space between input and uploaded file info */
// }

// .uploadedFile {
//   display: flex;
//   align-items: center;
//   margin-left: 10px; /* Space between multiple uploaded files */
// }

// .uploadedFileSpan {
//   margin-right: 10px;
// }

// .removeFileBtn {
//   background: none;
//   border: none;
//   color: red;
//   cursor: pointer;
//   font-size: 16px;
// }

// .confirmation {
//   margin-top: 20px;
//   text-align: center;
// }

// .redirectBtn {
//   background-color: #28a745;
//   color: #fff;
//   padding: 10px 20px;
//   font-size: 16px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   margin-top: 10px;
// }

// @keyframes shimmer {
//   0% { background-position: -500% 0; }
//   100% { background-position: 500% 0; }
// }

// @keyframes flash {
//   0%, 50%, 100% { background-color: #00ff00; } /* Green color */
//   25%, 75% { background-color: #ff69b4; } /* Pink color */
// }

// .flashySlogan {
//   font-size: 28px; /* Adjust size as needed */
//   font-weight: bold;
//   background: linear-gradient(to right, #00ff00, #ff69b4, #00ff00); /* Green to pink gradient */
//   background-size: 200% auto;
//   -webkit-background-clip: text; /* Clip text to the background */
//   background-clip: text; /* Clip text to the background */
//   color: transparent; /* Hide original text color */
//   animation: shimmer 15s linear infinite, flash 3s infinite; /* Slower shimmer effect and flashing */
//   text-align: center; /* Center the text */
//   margin: 20px 0; /* Add some margin for spacing */
//   transition: transform 0.5s ease; /* Smooth transition for zoom effect */
// }

// .flashySloganHover {
//   animation: none; /* Stop the animation on hover */
//   color: #ff69b4; /* Set text color to pink */
// }

// .questionsWrapper {
//   display: flex;
//   justify-content: center; /* Centers the .questions horizontally */
//   align-items: center; /* Centers the .questions vertically */
//   min-height: 17vh;
// }

// .commonQuestionsWrapper {
//   display: flex;
//   justify-content: center;
//   margin-bottom: 20px;
// }

// .dangerSection {
//   background-color: #f8d7da; /* Light red background */
//   border: 1px solid #f5c6cb; /* Light red border */
//   color: #721c24; /* Dark red text */
//   padding: 10px;
//   border-radius: 5px;

//   .formGroupLabel {
//     color: #721c24; /* Dark red text for labels */
//   }

//   .uploadedFileSpan {
//     color: #721c24; /* Dark red text for uploaded file names */
//   }

//   .removeFileBtn {
//     background-color: #f5c6cb; /* Light red background for remove button */
//     color: #721c24; /* Dark red text for remove button */
//   }
// }

// .AiRag {
//   .Question {
//     .Label {
//       flex: 0 1 250px; /* Fixed width for labels */
//       margin-right: 10px;
//       font-size: 18px; /* Increase the font size */
//       white-space: nowrap; /* Prevent line breaks */
//       text-align: left; /* Align text to the left */
//     }
//   }
// }






/* Common styles */
.aiForm {
  max-width: 800px; /* Increase the width to give more space */
  margin: 0 auto;
  padding: 20px;
}

.formGroup {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure elements take full width */
  flex-wrap: nowrap; /* Prevent line breaks */
}

.label {
  flex: 0 1 20px; /* Fixed width for labels */
  margin-right: 10px;
  font-size: 18px; /* Increase the font size */
  white-space: nowrap; /* Prevent line breaks */
  text-align: left; /* Align text to the left */
}

.select,
.inputFile {
  flex: 1; /* Allow input to grow and fill space */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: right; /* Align text to the right */
}

.submitBtn {
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.textIntro {
  margin-left: 50px;
  margin-right: 50px;
}

.aiDescription {
  font-style: italic;
  color: #888;
}

.multiContainer {
  margin-left: 20%;
  margin-right: 20%;
}

.optionBold {
  font-weight: bold;
}

.aiFormPadding {
  padding: 0 20px; /* Padding on left and right */
}

.aiSubmitText {
  margin: 50px 50px; /* Margin on left and right */
  padding: 10px 0;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
  font-size: larger;
}

.uploadedFiles {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between input and uploaded file info */
}

.uploadedFile {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Space between multiple uploaded files */
}

.uploadedFileSpan {
  margin-right: 10px;
}

.removeFileBtn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
}

.confirmation {
  margin-top: 20px;
  text-align: center;
}

.redirectBtn {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

@keyframes shimmer {
  0% { background-position: -500% 0; }
  100% { background-position: 500% 0; }
}

@keyframes flash {
  0%, 50%, 100% { background-color: #00ff00; } /* Green color */
  25%, 75% { background-color: #ff69b4; } /* Pink color */
}

.flashySlogan {
  font-size: 28px; /* Adjust size as needed */
  font-weight: bold;
  background: linear-gradient(to right, #00ff00, #ff69b4, #00ff00); /* Green to pink gradient */
  background-size: 200% auto;
  -webkit-background-clip: text; /* Clip text to the background */
  background-clip: text; /* Clip text to the background */
  color: transparent; /* Hide original text color */
  animation: shimmer 15s linear infinite, flash 3s infinite; /* Slower shimmer effect and flashing */
  text-align: center; /* Center the text */
  margin: 20px 0; /* Add some margin for spacing */
  transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}

.flashySloganHover {
  animation: none; /* Stop the animation on hover */
  color: #ff69b4; /* Set text color to pink */
}

.questionsWrapper {
  display: flex;
  justify-content: center; /* Centers the .questions horizontally */
  align-items: center; /* Centers the .questions vertically */
  min-height: 17vh;
}

.commonQuestionsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dangerSection {
  background-color: #f8d7da; /* Light red background */
  border: 1px solid #f5c6cb; /* Light red border */
  color: #721c24; /* Dark red text */
  padding: 10px;
  border-radius: 5px;

  .formGroupLabel {
    color: #721c24; /* Dark red text for labels */
  }

  .uploadedFileSpan {
    color: #721c24; /* Dark red text for uploaded file names */
  }

  .removeFileBtn {
    background-color: #f5c6cb; /* Light red background for remove button */
    color: #721c24; /* Dark red text for remove button */
  }
}

.AiRag {
  .Question {
    .Label {
      flex: 0 1 250px; /* Fixed width for labels */
      margin-right: 10px;
      font-size: 18px; /* Increase the font size */
      white-space: nowrap; /* Prevent line breaks */
      text-align: left; /* Align text to the left */
    }
  }
}

.commonQuestionsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Center common questions */
}

.questionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aiQuestionLabel {
  font-size: 18px;
  margin-bottom: 10px;
}

.questionSplitWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.leftColumn,
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftColumn {
  margin-right: 20px;
}

.rightColumn {
  margin-left: 20px;
}

.questionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questionsColumns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.leftColumn, .rightColumn {
  flex: 1;
  padding: 10px;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.label {
  flex: 0 1 25px;
  margin-right: 10px;
  font-size: 18px;
  white-space: nowrap;
  text-align: left;
}

.inputFile {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: right;
}

.AiRagQuestionLabel {
  flex: 0 1 25px;
  margin-right: 10px;
  font-size: 18px;
  white-space: nowrap;
  text-align: left;
}
