@import '../imports';
@import '../utils/constants';

.wrapper {
  width: calc(100% - 74rem);
}

.freqOfPaymentWrapper {
  @include size($max-width: 70rem, $width: 100%);
  @extend .horizontalCenter;
  flex-wrap: wrap;
  margin-right: auto;

  @include tabletLandscape {
    justify-content: space-around;
    margin: 5rem auto 0;
  }

  &Settings{
    @include mobileLarge{
      @include size($width:100%);
    }
    @include size($width:40%);
    padding: 0 2rem;
    margin: 0 auto;
    display: block;
    .frequencyBlock{
      @include size($width:100%);
      margin: 0 !important;
      min-height: unset;
    }
  }

}

.subscriptionContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;

  @include tabletLandscape {
    flex-direction: column;
  }
}

.frequencyBlock {
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  @include size($width: calc(50% - 4rem), $min-height: 32rem);
  @include prefix(transition, all 0.2s ease-in-out, webkit moz ms o);
  padding: 5.5rem 3.7rem;
  box-shadow: 0 .3rem .6rem rgba($default-color, .16);
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;

  &:hover {
    box-shadow: 0 .3rem 2.1rem rgba($default-color, .16);
  }

  @include desktopMedium {
    padding: 3.5rem 3.7rem;
  }

  @include desktopSmall {
    width: 100%;
  }

  @include tabletLandscape {
    width: calc(50% - 4rem);
  }

  @include mobileLarge {
    width: 100%;
  }

  &:nth-of-type(odd) {
    margin-right: 4rem;

    @include desktopSmall {
      margin-right: 0;
    }

    @include tabletLandscape {
      margin-right: 4rem;
    }

    @include mobileLarge {
      margin-right: 0;
    }
  }

  &:first-of-type {
    .frequencyType,
    .pricePerMonth,
    a {
      color: $third-color;
    }

    a {
      border-color: $third-color;
    }
  }
}

.frequencyButtonWrapper {
  @include position(absolute, null, null, 5rem, 0);
  width: 100%;

  button {
    @include size($width: 14rem, $height: 2.9rem);
    margin: 0 auto;
  }
}

.frequencyType,
.pricePerMonth,
.paymentValue {
  @include font($font-size: 2rem, $font-weight: $bold);

  @include desktopMedium {
    font-size: 1.6rem;
  }
}

.frequencyType,
.pricePerMonth {
  color: $menu-accent;
}

.pricePerMonth {
  font-size: 1.7rem;
  margin-bottom: 5rem;

  @include desktopMedium {
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }
}

.discount {
  @include font($font-size: 2rem, $font-weight: $light);
  margin-bottom: 3.7rem;

  @include desktopMedium {
    margin-bottom: 2rem;
    font-size: 1.6rem;
  }
}
