@import '../../imports';
@import '../../utils/constants';

.contents {
  &CoverImageWrapper {
    @include mobileLarge {
      margin: 3rem 0 6rem;
    }
    margin-top: 2rem;
    padding: 0;

    img {
      @include position(absolute, -.4rem, null, null, 0);
      @include size($min-width: 100%, $min-height: 100%);
    }
  }

  &FormElement > div {
    padding-bottom: 4rem;
  }

  &Title {
    @include font($font-size: 1.6rem, $color: $menu-accent, $font-weight: $bold);
    transition: transform 0.3s;
  }

  &InputsWrapper {
    & > div:first-of-type {
      padding-bottom: 4rem;
    }
  }
  
  &UploadValue {
    span {
      @include size($max-width: 20rem, $width: calc(100% - 3.5rem));
    }
  }

  &PreviewBlock {
    @include desktopSmall {
      height: 15rem;
    }
    @include size($width:  100%, $height: 19rem);
    @extend.totalCenter;
    margin-bottom: 4rem;
    position: relative;
    overflow: hidden;
    word-break: break-word;
    text-align: center;
  }
  
  &DefaultCoverImage {
    @include position(absolute, 0, null, null, 0);
    @include size($width: 100%, $height: 100%);
    border: .1rem solid $border-dark;
  }

  &CoverImage {
    @include position(absolute, -.4rem, null, null, 0);
    min-width: 100%;
  }

  &CoverTitle {
    @include tabletLandscape {
      font-size: 3.2rem;
    }
    @include mobileMedium {
      font-size: 2.1rem;
    }
    @include font($color: $default-color, $font-size: 4.4rem, $font-weight: $bold);
    @extend .verticalCenter;
    text-shadow: $shadow;
    z-index: 1;
    padding: 0 3rem;
    height: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;

    & > button {
      @include position(absolute, 1rem, 0, null, null);
    }
  }
}

