@import '../../imports';

.programHyperContainer {
  @include respond-to-max('tablet-landscape') {
    margin-top: 10rem;
    gap: 5rem;
    grid-template-columns: repeat(auto-fit, minmax(23rem, auto));
  }
  @include respond-to-max('desktop-medium') {
    padding-left: 5rem !important;
  }
  @include respond-to-max-height('custom-resolution-1') {
    grid-template-columns: minmax(22rem, 28rem) auto;
    gap: 5rem;
  }
  @include respond-to-max-height('custom-resolution-2') {
    grid-template-columns: minmax(15rem, 22rem) auto;
  }
  @include respond-to-max('desktop-small') {
    padding: 0 1.5rem!important;
  }
  @include respond-to-max('mobile-large') {
    grid-template-columns: 100%;
  }
  padding: 0 1.5rem 0 15rem!important;

  display: grid;
  grid-template-columns: minmax(25rem, 32rem) auto;
  grid-gap: 10rem;
}
