@import '../imports';
@import '../utils/constants';

.btn {
  @include font($font-size: 1.6rem);
  border: .1rem solid transparent;
  padding: .4rem 3.8rem;
  border-radius: 3rem;
  cursor: pointer;
  min-height: 3rem;
  line-height: inherit;

  svg {
    margin-top: 0.3rem;
  }
}

.btnHover {
  transition: all .2s;

  &:hover {
    box-shadow: $light-shadow;
  }
}

.primary {
  @extend .btnHover;
  @include buttonStyle($forth-color, $secondary-color);
  @include prefix(transition, all .3s, webkit moz ms o);

  &inverted {
    @extend .btnHover;
    @include buttonStyle($secondary-color, $forth-color, .1rem solid $secondary-color);
  }
}

.alt {
  @extend .btnHover;
  @include buttonStyle($forth-color, $primary-color);
  @include prefix(transition, all .3s, webkit moz ms o);
}

.secondary {
  @extend .btnHover;
  @include buttonStyle($forth-color, $primary-color);
  @include prefix(transition, all .3s, webkit moz ms o);

  &inverted {
    @include buttonStyle($third-color, $forth-color, .1rem solid $third-color);
  }
}

.danger {
  @extend .btnHover;
  @include buttonStyle($forth-color, $primary-color);
}

.disabled {
  @include buttonStyle($border-light, $forth-color);
  border: .1rem solid $border-light;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.textOnly {
  @include buttonStyle($primary-color, transparent);
  padding: .4rem 3.8rem;
}

.textOnlyinverted {
  @extend .textOnly;
  color: $forth-color;
  border: none;
}

.withIcon {
  @include buttonStyle($primary-color, transparent);
  @extend .verticalCenterColumn;
  padding: .4rem 0;
  font-size: 1.5rem;

  .iconWrapper {
    padding: .8rem;
    border-radius: 2.5rem;
    margin-bottom: .5rem;
  }

  svg {
    @include font($color: $forth-color, $font-size: 1.5rem);
  }
}

.withIconDisabled {
  @include buttonStyle($primary-color, transparent);
  color: $border-light;
  padding: .4rem 0;

  .iconWrapper {
    background: $border-light;
  }
}

/**
* Force button background color
*/
// button[class*="Button_danger"]{
//   background-color:  $primary-color !important;
// }
// button[class*="Button_primary"]:not(button[class*="platformSuperCTA"]):not(button[class*="platformSubCTA"]):not(button[class*="platformProgramCTA"]){
//   background: linear-gradient(90deg, $third-color-accent 0%, $third-color 73%, $third-light-color 100%) !important;
// }
