@import '../../imports';

.userHeader {

  &Space1{
    @include size($min-width: 13rem);
  }

  &Space2{
    @include size($min-width: 20rem);
  }

  &Element {
    @include font($font-size: 1.4rem, $color: $gray-accent-color, $font-weight: map_get($font-weights, 'bold'));
    @include display-flex();
    width: 100%;
    padding: 2rem;

    svg {
      color: $default-color;
    }

    &Block{
      @include display-flex();
      flex: 1;
    }
  }

  &Sort {
    position: relative;

    svg {
      @include position(absolute, .2rem, $left: -.5rem);
      font-size: 1.2rem;
      width: 2rem !important;
      color: $gray;

      &.userHeaderSortActive {
        color: $primary-color-secondary-accent;
      }
    }

    svg + svg {
      top: .4rem;
    }

  }
}

.userList {
  justify-content: flex-start !important;

  .userHeader {
    &Element {
      @extend .flex-center-total;
      @extend .flex-justify-start;

      &Block{
        @include display-flex();
        flex: 0 0 13rem;
      }

    }
    &Role,
    &Status,
    &Date,
    &Name{
      width: auto;
    }

    &Role,
    &Status {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
