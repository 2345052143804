@import '../imports';
@import '../utils/constants';

.loginModal {
  @include size($width: 100%, $height: 100vh, $max-width: none);
  @include font($color: $forth-color, $text-align: initial);
  @extend .totalCenter;
  @extend .resetToDirectionColumn;
  position: relative;
  border-radius: 0;
  padding: 2rem 1.5rem;
  background: transparent;

  &:after {
    @include size($width: 100%, $height: 100vh);
    @include position(absolute, 0, 0, null, null);
    background: $gradient;
    content: '';
    opacity: 1;
    z-index: -1;
  }

  input {
    border-radius: 3rem;
    max-width: none;
    border: 0.1rem solid $border-medium;
    background: $forth-color;
  }

  label {
    @include font($color: $forth-color, $font-size: 1.6rem, $font-weight: $bold);
    margin-bottom: 1.2rem;
  }

  form {
    @include size($width: 100%, $max-width: 34.9rem);

    & > div {
      max-width: none;
    }
  }

  svg {
    font-size: 3.2rem;
  }

  .closeBtn {
    @include position(absolute, -4rem, 0, null, null);
    margin-bottom: 1rem;
    cursor: pointer;
    margin-left: auto;
    display: flex;
    border: none;
    background: transparent;
    color: $forth-color;
    padding: 0;
  }

  .closeBtnWrapper {
    width: 34.9rem;
    position: relative;

    @include mobileMedium {
      width: 28rem;
    }

    span {
      @include font($font-size: 2.4rem, $font-weight: $bold, $line-height: 1.2);
      margin-bottom: 3.3rem;
      display: block;
    }
  }
}

.buttonWrapper {
  @include display(flex, space-between, $flex-direction: row-reverse, $align-items: center);
  @extend .horizontalCenter;
  margin-top: 3rem;

  button[type=button] {
    margin-left: 0;
    padding: .4rem 0 .4rem;
    width: auto;
  }

  button {
    @include size($width: 100%, $max-width: 15.8rem);
    @extend .horizontalCenter;

    svg {
      @include font($color: $forth-color, $font-size: 1.6rem);
    }
  }
}

.strenghtMeterWrapper {
  @include font($font-size: 1.6rem, $font-weight: 600, $color: $forth-color);
}
