@import '../../imports';
@import '../../utils/constants';

.wrapper {
  @extend .horizontalCenter;
  position: relative;
  z-index: -1;
  top: 2rem;

  &List {
    @extend .horizontalCenter;
    margin-top: 2rem;
    list-style-type: none;
    position: relative;

    &:after {
      @include size($width: 100%, $height: 0.2rem);
      @include position(absolute, 50%, 0, null, null);
      background: $secondary-color;
      opacity: 0.3;
      content: '';
      z-index: 1;
    }
  }
}

.pageIndexStep {
  @include size($width: 1.9rem, $height: 1.9rem);
  @include font($font-size: 1.1rem, $color: $forth-color);
  @extend .totalCenter;
  border-radius: 1rem;
  background: lighten($secondary-color, 35%);
  z-index: 3;
  position: relative;
  pointer-events: none;

  & + & {
    margin-left: 1.5rem;
  }

  &.active {
    box-shadow: $light-shadow;
    background: $secondary-color;
    font-weight: 600;
  }
}

.progressActive,
.stepActive {
  background: $secondary-color;
}
