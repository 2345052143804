@import '../imports';

.input {
  &Base {
    @include size($width: 100%, $min-height: 3.4rem);
    @include font($font-family: $primary-font, $color: $gray, $font-size: 1.8rem, $font-weight: map_get($font-weights, 'light'));
    border: none;
    padding: .5rem 1.5rem;
  }

  &Rounded {
    border-radius: 2rem;
  }

  &Dropdown {
    background: #EC407A;
    &__value-container {
      background: #EC407A;
    }
  }

  &Wrapper {
    position: relative;
    width: 100%;
  }
}

//input variations
.inputRoundedWithDefaultColorBackground {
  @extend .inputBase;
  @extend .inputRounded;
  background: $default-color;
}

.inputWithLineEdit {
  @extend .inputBase;
  border-bottom: .1rem solid $border-dark-color;
  background: url('#{$editPen}') no-repeat center right $default-color;
}

.selectRoundedWithDefaultColorBackground {
  @extend .inputBase;
  @extend .inputRounded;
  background: $default-color;
  padding: 0;
  position: relative;
}

.label {
  &Small, &Medium, &Large {
    font-family: $primary-font;
  }

  &Small {
    font-size: 1.6rem;
  }

  &Medium {
    font-size: 1.8rem;
  }

  &Large {
    font-size: 2rem;
  }

  &Active {
    color: $secondary-color;
  }

  &WithAnimation {
    @include font($font-family: $primary-font, $font-size: 1.8rem, $color: $gray, $font-weight: map_get($font-weights, 'light'));
    @include position(absolute, .3rem, null, null, 1.3rem);
    @include prefix(transition, all .2s linear, moz webkit ms o);
    pointer-events: none;

    &Active {
      @include font($font-size: 1.3rem, $color: $secondary-color, $font-weight: map_get($font-weights, 'bold'));
      top: -.7rem;
    }
  }
}

.labelLargeBoldWithDefaultColor {
  @extend .labelLarge;
  @include font($font-weight: map_get($font-weights, 'bold'), $color: $default-color);
  display: block;
}

.labelMediumThinWithGrayColor,
.radioButtonLabel {
  @extend .labelMedium;
  @include font($font-weight: map_get($font-weights, 'thin'), $color: $gray);
  display: block;
}

//Specific style for Contact form
.labelContactSpacing {
  margin-bottom: 1rem;
}

.inputContactSpacing {
  margin-bottom: 2.5rem;
}

//Specific style for Login modal
.inputLoginSpacing {
  margin-bottom: 2.5rem;
  padding: 1rem 1.5rem;
}

//inputCreateAccuntSpacing
.inputCreateAccuntSpacing {
  margin-bottom: 2.5rem;
}

//Specific style for input on tailored form
.inputTailoredFormSpacing {
  margin-bottom: 8rem;

  &Small {
    margin-bottom: 2.5rem;
  }
}



.fieldError {
  position: relative;
}

.error {
  @include position(absolute, null, 0, .8rem, 0);
  @include font($font-family: $primary-font, $color: $danger-color, $font-size: 1.2rem, $text-align: center);
}


.radioButton {
  @include position(absolute, 0, null, null, 50%);
  @include prefix(transform, translateX(-50%), webkit moz o ms);
  @include size($width: 2rem, $height: 2rem);
  border-radius: 50%;
  border: .1rem solid $gray-light-accent-color;
  background: $default-color;

  &Wrapper {
    position: relative;
    padding-top: 2rem;

    input {
      display: none;

      &:checked ~ .radioButton::after {
        @include position(absolute, 50%, null, null, 50%);
        @include size($width: 1rem, $height: 1rem);
        @include prefix(transform, translate(-50%, -50%), moz webkit ms o);
        content: '';
        left: 50%;
        border-radius: 50%;
        background-color: $secondary-color;
      }
    }
  }

  &Inline {
    display: flex;
    justify-content: space-evenly;
  }
}
