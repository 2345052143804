@import '../../imports';
@import '../../utils/constants';

.wrapper {
  padding-top: 4rem;
  width: 100%;

  form {
    width: 100%;

    & > div:first-of-type {
      @include size($width: 100%, $max-width: 36.6rem);
    }
  }
}

.buttonWrapper{
  width: 100%;
  max-width: 60rem;
}

.wrapperCenter {
  width: 100%;


  form {
    & > div:first-of-type {
      margin: 0 auto;
    }
  }

  [class*="Input_datepicker"]{
    label{
      font-weight: 100 !important;
      padding-left: 1.5rem;
    }
  }

}

.extraInfo {
  @extend .verticalCenter;
  @extend .resetToDirectionColumn;
  margin: 1rem 0 2rem;
  width: 100%;

  button[type='submit'] {
    margin-top: 2.5rem;
    background: $gradient;
    border: none;
    color: $forth-color;
  }

  & > button[type='button'] {
    margin-top: 2rem;
  }
}

.onBoarding {
  @include mobileLarge {

    .addImage {
      max-width: 90%;
      margin: 0 auto;
      :global {
        .fileUploader {
          @include mobileLarge {
            margin-top: 0 !important;
            padding-left: 0;
          }
        }
        .fileUploader {
          margin-top: 0 !important;
        }
        .fileContainer{
          align-items: center;
          margin-top: -6rem;
        }
      }
    }
    .deleteImage{
      width: 5rem;
    }
  }
}

.addImage {
  @include size($width: 100%, $max-width: 27.5rem);
  text-align: center;
  position: relative;
  margin-bottom: 3rem;

  @include mobileLarge {
    min-height: 13rem;
  }


  :global {
    .chooseFileButton {
      @include font(
              $font-size: 1.8rem,
              $font-weight: $light,
              $color: $primary-color !important,
              $font-family: $default-font
      );
      cursor: pointer;
      @include display($display: flex, $align-items: center);
      z-index: 10;
      padding-left: 0;

      @include mobileLarge {
        margin: 0 auto;
      }
    }

    .fileContainer {
      @include size($width: 100%, $max-width: 36.6rem);
      margin-top: 0;
      align-items: flex-start;
      box-shadow: none;
      padding: 0;
      background: none;

      .errorsContainer {
        @include mobileLarge {
          @include size($max-width: none, $width: 100%);
          margin-top: 1rem;
          text-align: center;
        }
      }
    }

    .fileUploader {
      display: block;
      padding-left: 6rem;
      position: relative;

      @include mobileLarge {
        margin-top: 5rem;
        padding-left: 0;
      }
    }
  }
}

.deleteImageWrapper {
  @extend .spaceBetween;
  @include size($width: 100%, $max-width: 36.3em);
  margin: 1.8rem auto 0;
}

.deleteImage {
  @include mobileLarge{
    position: static;
  }
  @include position(absolute, -1.4rem, null, null, 0);
  width: 100%;
  z-index: 10;

  button {
    @include font($color: $third-color, $font-size: 1.2rem, $font-weight: $bold);
    border: none;
    padding: 0;
    z-index: 11;

    @include mobileLarge {
      @include position(absolute, null, 0, null, null);
    }

    &:hover {
      color: $danger;
    }
  }

  img {
    @include size($width: 4.9rem, $height: 4.9rem);
    border: 0.2rem solid $forth-color;
    border-radius: 50%;
    box-shadow: $light-shadow;

    @include mobileLarge {
      margin: 0 auto;
    }
  }

  svg {
    margin-left: 1.5rem;
  }
}

.defaultImage {
  @include size($width: 4.9rem, $height: 4.9rem);
  border: 0.2rem solid $forth-color;
  border-radius: 3rem;
  box-shadow: $light-shadow;
  background: $menu-accent;
  margin-right: 1.5rem;
  content: '';

  @include mobileLarge {
    margin: 0 auto;
  }
}

.labelsWrapper {
  margin-top: 3rem;
}

.error {
  @include error(null, null, null, 0, 0);
}

