@import "../../imports";

.endOfProgram {
  background: $danger-color;
  padding: .4rem 1.8rem;
  cursor: default;
}

.agreementAdmin {
  background: $label-inactive;
}

.timeOfAllocation {
  background: $menu-accent;
}

.pointsConversion {
  &CloseCta {
    @include position(absolute, 2rem, 2rem);
  }

  &Wrapper {
    @include display-flex($align-items: center, $justify-content: center);
    user-select: none;
  }

  &Icons {
    width: 10%;

    &Disabled {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &Input {
    width: 70%;
    margin: 0 5%;
    border: none;
    text-align: center;
    padding: 1rem 0;
    background: transparent;
  }
}
