@import '../imports';
@import '../utils/constants';

.checkbox {
  &Container {
    @include size($max-width: 56rem, $width: 100%);
    margin: 0 auto 6rem;
    font-size: 1.2rem;

    &:not(:last-of-type) .checkboxLabel {
      cursor: pointer;
    }

    &:last-of-type {
      pointer-events: none;

      label {
        display: flex;
        margin-left: 1.8rem;
      }
    }
  }

  &Label {
    @include font($font-size: 1.6rem, $color: $primary-color, $font-weight: bold);
    opacity: 0.85;
  }

  &Info {
    padding-left: 1.8rem;
    opacity: 0.6;
  }
}

.noCheckbox {
  display: none;
}
