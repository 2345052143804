@import "../../imports";

.comments {
  margin-top: 1rem;
  position: relative;
  word-break: break-word;

  [class*="WallPostBaseBlock_wallPostFilePreview"]{
    position: absolute;
    top:4rem;
    max-height: 8rem;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
  }

  &Spinner {
    color: $primary-color;;
    margin-top: 1.5rem;
    text-align: center;
  }

  .commentProfilePicture {
    @include size($width: 4.5rem, $height: 4.5rem,$min-width:4.5rem);
    @include border-radius(50%);
    @include border($border-color: $default-color, $border-width: 0.3rem, $border-style: solid);
    margin-right: .5rem;
    box-shadow: $shadow-comment;
  }

  .addCommentInput {
    @include respond-to-max('desktop-medium'){
      padding: 1.2rem 12rem 0 1rem;
    }
    @include respond-to-max('mobile-large'){
      min-height: 8rem;
    }
    @include border-radius(2rem);
    @include border($border-color: $gray-lighter-color, $border-width: 0.1rem, $border-style: solid);
    @include size($max-width: 75rem, $min-width: calc(100% - 5.5rem), $max-height: 30rem, $min-height: 4.5rem, $height: 4.5rem);
    padding: 1.2rem 12rem 0 1rem;
    width: 100%;
    outline: 0;
    resize: vertical;
    &.isFileSelected{
      min-height: 15rem;
      padding-right: 12rem;
    }

  }

  .addCommentButtons {
    @include display-flex($flex-direction:row);
    position: absolute;
    color: $gray-accent-color;
    right: 2rem;
    top: 0;
    cursor: pointer;

    .submitComment {
      margin-top: 1.5rem;
    }
  }
}
