@import "../../imports";

.socialNetworks {

  &Subtitle {
    color: $primary-color;
    margin-top: 0;
  }

  &Label {
    @include font($color: $primary-color, $font-weight: map_get($font-weights, 'bold'));
    margin-top: 5rem;
  }

  &Info{
    @include respond-to-max('mobile-large'){
      text-align: center;
      margin: 1.5rem 0;
    }
    margin-left: 2.5rem;
  }

  &Block {
    margin: 3rem 0;
  }

  &Element{
    @include display-flex($flex-direction: row);
    &.textIconElement{
      @include respond-to-max('mobile-large'){
        flex-direction: column;
      }
    }
  }

  &Input{
    max-width: 30rem;
    padding-bottom: 0;
    ::placeholder{
      font-size: 1.4rem;
    }
    &.disabled{
      input{
        pointer-events: none;
      }
    }
  }

  &SwitchButton{
    @include respond-to-max('mobile-large'){
      margin-top: 1rem !important;
    }
    margin-top: 1.2rem !important;
  }

  &Icon{
    @include respond-to-max('mobile-large'){
      margin: 1rem auto;
    }
    @include size($width:2.5rem, $height: 2.5rem);
    display: block;
    margin-left: 1rem;
  }

}

.textBlock{
  @include respond-to-max('mobile-large'){
    display: block;
  }
}

.inputHolder{
  margin: 0 0 0 1.5rem;
}

.formError{
  @include font($font-size:1.2rem, $color:$danger-color);
}

.nextButtonHolder {
  width: 100%;
  text-align: center;
}

