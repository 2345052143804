@import '../../imports';

.programSlider {
  &Container {
    @include font($font-size: 1.4rem, $color: $primary-color);
    margin: 0 auto 1rem;
    border: .1rem solid $primary-color;
    width: 18rem;
    padding: .3rem 1rem;
    border-radius: 3rem;

    &.white {
      @include font($font-size: 2rem, $font-weight: map_get($font-weights, 'bold'), $color: $default-color !important);
      width: 24rem;
      margin: 0 !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }

    & + .programSliderContainer {
      margin-bottom: 1.5rem;
    }
  }

  &Wrapper {
    @extend .flex-center-total;
    text-align: center;
    padding: 0 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;

    &ArrowsWhite {
      background-color: $primary-color;
      button::before {
        color: $default-color !important;
      }
    }

    button {
      @include on-event {
        color: inherit
      };
      z-index: 7;
      cursor: pointer;
      color: inherit;

      &:before {
        @include font($font-weight: map_get($font-weights, 'bold'), $font-size: 1.5rem, $font-family: $font-awesome, $color: inherit);
        content: $chevron-right-icon;
        opacity: 1;
      }

      &:first-of-type {
        left: 0 !important;

        &:before {
          content: $chevron-left-icon;
        }
      }

      &:last-of-type {
        right: 0;
      }
    }

    &.white {
      button {
        &:before {
          color: $default-color
        }
      }
    }
  }
}

.smallSlider{
  .sliderProgram{
    padding: 0 1rem !important;
  }
}

.sliderProgram {
  @include safariOnly() {
    top: 0;
  }
  @include position(relative, .3rem);
  overflow: hidden;
  padding: 0 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: block;
}

.programTooltip {
  background-color: $default-color !important;
  color: $default-text-color !important;
  box-shadow: $shadow-tight;

  &:after {
    border-top-color: $default-color !important;
  }
}

.subPlatform {
  background: $secondary-color!important;
  color: $default-color!important;
  border: none;
}

.superPlatform {
  background: $secondary-color!important;
  color: $default-color!important;
  border: none;
}
