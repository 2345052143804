@import '../imports';
@import '../utils/constants';

.extendedInput {
  @include display($align-items: center, $flex-direction: row !important);

  input {
    @include font($text-align: right, $color: $menu-accent, $font-weight: $bold, $font-size: 1.4rem);
    @include size($width: 30%, $max-width: 21rem);
    border: .1rem solid $border-light;
    border-radius: 2rem;
    background: $forth-color;
    padding: .6rem 1.5rem;
    margin-right: 0;
  }

  label {
    @include font($font-size: 1.4rem, $color: $default-color);
    width: 59%;
  }

  .extraInfo {
    @include mobileLarge {
      padding-left: .5rem;
      font-size: 1.3rem;
    }
    padding-left: .2rem;
    width: 2rem;
    font-size: 1.4rem;
  }
}

.error {
  @include mobileLarge {
    text-align: right;
    width: 23rem;
  }
  @include position(absolute, null, 0, .8rem, null);
  @include font($font-size: 1.2rem, $color: $danger, $text-align: center);
  width: 33%;
  padding-right: 2rem;
}
