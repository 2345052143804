$fontPath: '../../fonts/';
$imgPath: '../../images/';
$imgPath2: '../../../images/';
@import './variables';

$gradient: linear-gradient(90deg, $third-color 0%, $third-color 73%, $third-light-color 100%);
$second-gradient: linear-gradient(133deg, $third-color 0%, $third-color 35%, $third-light-color 73%, $third-light-color 100%);
$logo-gradient: linear-gradient(133deg, #0e0439 0%, #0e0439 35%, #170e40 73%, #201746 100%);
$sidebar-menu-gradient: linear-gradient(133deg, #00063A 65%, #3e216b, #3f216bc4);
$light-shadow: 0.1rem 0.25rem 1.5rem 0.2rem rgba(0, 0, 0, 0.12);
$shadow: 0rem 0.2rem 1.5rem 0.5rem rgba(0, 0, 0, 0.1);
$full-shadow: 0.1rem 0.2rem 1rem 0.3rem rgba(0, 0, 0, 0.21);
$fontAwesome: 'Font Awesome 5 Free';

$bold: 600;
$regular: 400;
$light: 100;
