@import '../../imports';
@import '../../utils/constants';

.title {
  @include font($font-size: inherit, $font-weight: inherit);
  padding-bottom: 1.2rem;
  padding-right: 1.5rem;
  display: inline-block;
}

.strength {
  @include font($font-size: 1.6rem, $font-weight: $bold);
  padding: 1.5rem 0;

  &.seriously,
  &.weak {
    color: $danger;
  }

  &.whyNot {
    color: $default-color;
  }

  &.great {
    color: $third-color;
  }

  &.awesome {
    color: $secondary-accent;
  }
}

.passwordStrength {
  &Content {
    padding: 0;
  }

  &Meter {
    height: 4rem;
    background: $border-medium;
    position: relative;
    border-radius: 3rem;

    &Filled {
      background: $secondary-color;
      height: 100%;
      border-radius: 3rem;
      transition: all .2s linear;

      &.seriously,
      &.weak {
        background-color: $danger;
      }

      &.whyNot {
        background-color: $default-color;
      }

      &.great {
        background-color: $third-color;
      }

      &.awesome {
        background-color: $secondary-accent;
      }
    }
  }
}
