@import '../../imports';
@import '../../utils/constants';

.wrapper {
  @include size($width: 100%, $max-width: 164rem);
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 2rem;
  padding: 0 1.5rem;
  justify-content: space-between;
}

.subscription {
  @include size($width: 100%, $max-width: 60rem);

  .labelsBlock {
    padding-top: 3rem;

    @include tabletPortrait {
      padding-top: 3.5rem;
    }

    @include mobileLarge {
      padding-top: 11.5rem;

      li {
        min-height: 3.4rem;
      }
    }

    @include mobileMedium {
      padding-top: 15rem;
    }
  }

  .pricingSlider {
    margin-top: -6rem;

    @include mobileLarge {
      margin-top: 2rem;
    }
  }

  @include mobileLarge {
    h3 {
      margin-bottom: 7rem;
    }
  }

  @include mobileMedium {
    padding-right: 0;
  }

  @include mobileSmall {
    padding: 0;
    margin: 0;
  }

  .slideWrapper {
    background: none;
    margin-left: 26rem;

    & > div {
      @include mobileLarge {
        height: 4.8rem;
      }
    }
  }

  :global {
    .slick-slide:nth-last-child(2),
    .slick-slide:last-child {
      & > div > div > div:nth-child(n + 2) {
        color: $secondary-color;
        opacity: 1;
      }
    }
  }
}

.labelsBlock {
  @include font($font-size: 1.4rem, $font-weight: $bold, $color: $default-color);
  max-width: 19.5rem;
  padding-right: 1rem;
  padding-top: 23.2rem;

  @include tabletPortrait {
    padding-top: 23.7rem;
  }

  @include mobileLarge {
    padding-top: 15.7rem;
    width: 16.7rem;
    font-size: 1.2rem;
  }

  @include mobileMedium {
    padding-top: 16.2rem;
    width: 14rem;
    font-size: 1.2rem;
  }

  @include mobileSmall {
    font-size: 1.1rem;
  }

  li {
    @extend .verticalCenter;
    height: 3.4rem;

    @include mobileMedium {
      height: 4.5rem;
    }

    &:first-child,
    &:nth-child(2) {
      min-height: 9rem;
    }
  }

  button {
    @include font($font-size: 1.4rem, $font-weight: $bold);
    border: none;
    padding: 0;
    margin-top: 2.7rem;

    @include mobileMedium {
      margin-top: 0.5rem;
      font-size: 1.2rem;
    }
  }
}

.formTitle {
  @include font($font-size: 2.2rem, $font-weight: $bold, $color: $default-color);
  margin-bottom: 3.8rem;
}

.formDescription {
  @include font($font-size: 1.3rem, $font-weight: $regular, $color: $third-color, $line-height: 1.3);
  padding: 6rem 0;

  @include mobileLarge {
    padding: 4rem 0 !important;
  }
}

.pricingSlider {
  @include font($text-align: center, $font-weight: $bold, $font-size: 1.3rem);
  width: calc(100% - 32rem);
  margin-top: 5rem;

  @include mobileLarge {
    width: calc(100% - 17rem);
    margin-top: -3rem;
  }

  @include mobileMedium {
    width: calc(100% - 14rem);
    font-size: 1.2rem;
  }

  a {
    @include font($color: $secondary-color, $font-size: 1.4rem, $font-weight: $regular, $text-transform: capitalize);
    border: 0.1rem solid $secondary-color;
    padding: 0.5rem 3.8rem;
    border-radius: 3rem;
    cursor: pointer;

    @include mobileMedium {
      padding: 0.5rem 3rem;
      width: calc(100% - 1rem);
    }
  }

  :global {
    .slick-track {
      display: flex;
      align-items: flex-end;
    }
  }
}

.slideWrapper {
  max-width: 26.5rem;
  padding-top: 3rem;
  border-radius: 1rem;
  margin: 0 1rem 1.5rem;
  box-shadow: $full-shadow;
  background: $forth-color;

  &.staticSlideWrapper {
    margin-left: 20rem;
    max-width: 23rem;

    @include tabletLandscape {
      max-width: 20rem;
    }

    @include mobileLarge {
      max-width: 17rem;
    }

      @include mobileMedium {
      max-width: 12rem;
    }

    @include mobileLarge {
      margin-left: 0;
      transform: translateX(100%);
    }
  }

  & > div:not(.setup) {
    @extend .totalCenter;
    border-bottom: 0.1rem solid $border-light;
    height: 3.4rem;

    &:last-child {
      border-bottom: none;
    }

    &.name {
      @include mobileMedium {
        align-items: flex-end !important;
      }
    }

    @include mobileMedium {
      padding: 0 0.2rem;
      min-height: 4.5rem;
    }
  }

  @include tabletPortrait {
    margin: 0.5rem auto 1.5rem;
  }

  @include mobileMedium {
    margin: 1rem 1rem 1.5rem;
  }
}

.name {
  @include font($font-size: 2.2rem, $color: $default-color, $text-transform: capitalize);
  align-items: flex-start !important;
  height: auto !important;
}

.price {
  @include font($font-size: 1.7rem, $color: $third-color);

  &:after {
    content: '€';
    display: inline-block;
  }
}

.name,
.price {
  border-bottom: none !important;
}

.cta {
  height: 5.5rem !important;
  align-items: flex-start !important;

  &:last-of-type {
    margin-top: 2rem;
  }
}

.setup,
.typeOfPrograms {
  min-height: 9rem !important;
  font-weight: $regular;
  opacity: 0.6;
  padding: 1rem 0.7rem;
}

.setup {
  @include display($display: flex, $justify-content: center, $flex-direction: column);
  border-bottom: 0.1rem solid $border-light;

  p {
    width: 100%;

    &:last-child {
      font-style: italic;
    }
  }
}

button {
  @include font($color: $secondary-color, $font-size: 1.6rem);
  border: 0.1rem solid $secondary-color;
  padding: 0.4rem 3.8rem;
  border-radius: 3rem;
  background: $forth-color;
  cursor: pointer;
}

.typeOfPrograms {
  padding: 0 1rem;
}

.checked::before,
.unchecked::before {
  @include font($font-weight: $bold, $font-size: 1.3rem, $font-family: $fontAwesome, $color: $menu-accent);
  display: inline-block;
  content: '\f00c';
}

.unchecked::before {
  content: '\f00d';
  color: $danger;
}

.subscription {
  position: relative;

  .title {
    @include font($font-size: 3.7rem, $font-weight: $bold);
    @include position(absolute, -4rem, null, null, null);

    @include mobileMedium {
      top: 0;
    }
  }
}

.base {
  .title {
    display: none;
  }

  :global {
    .slick-slide:nth-child(3) {
      & > div > div {
        background: $third-color;
      }

      div::before,
      div {
        color: $forth-color !important;
        opacity: 1;
      }

      a {
        background: $forth-color;
        color: $third-color;
        border-color: $forth-color;
      }
    }

    .slick-slide:nth-child(4) {
      * {
        color: $menu-accent;
      }

      & > div > div > div:nth-of-type(1) {
        color: $default-color;
      }

      a {
        background: $gradient;
        border: none;
        color: $forth-color;
      }

      .setup,
      .typeOfPrograms {
        @include font($font-weight: $bold, $color: $menu-accent);
        opacity: 1;
      }

      div {
        opacity: 1;
        font-weight: $bold;
      }

      .price {
        color: $menu-accent;
      }

      & > div > div:first-of-type::before {
        @include font($font-weight: $regular, $font-size: 4.3rem, $font-family: $fontAwesome);
        content: $star-icon;
        display: inline-block;
        margin: -2rem 0 0.5rem;
      }
    }

    .slick-slide:nth-child(5) {
      * {
        color: $secondary-accent;
      }

      & > div > div > div:nth-of-type(1) {
        color: $default-color;
      }

      a {
        background: $gradient;
        border: none;
        color: $forth-color;
      }

      .setup,
      .typeOfPrograms {
        @include font($font-weight: $bold, $color: $secondary-accent);
        opacity: 1;
      }

      div {
        opacity: 1;
        font-weight: $bold;

        &:before {
          color: $secondary-accent !important;
          opacity: 1;
        }
      }

      a {
        background: $secondary-accent;
        color: $forth-color;
      }

      .price {
        color: $secondary-accent;
      }

      & > div > div:first-of-type::before {
        @include font($font-weight: $regular, $font-size: 4.3rem, $font-family: $fontAwesome);
        content: $flag-icon;
        display: inline-block;
        margin: -2rem 0 0.5rem;
      }
    }
  }
}

.additional {
  padding-top: 3rem;

  .title {
    @include font($color: $secondary-color, $font-size: 3.5rem, $font-weight: $bold);
    flex: 0 0 100%;
    margin-top: 5rem;

    @include tabletPortrait {
      margin-top: 8rem;
    }

    @include mobileLarge {
      font-size: 3rem;
      margin-top: 4rem;
    }
  }

  .pricingSlider {
    margin-top: -7rem;

    @include tabletPortrait {
      margin-top: -7.4rem;
    }

    @include mobileLarge {
      margin-top: 4rem;
    }
  }

  .labelsBlock {
    max-width: 29.5rem;
    padding-top: 1.7rem;

    li:first-of-type,
    li:nth-child(2) {
      height: 3.4rem;

      @include mobileMedium {
        height: 4.5rem;
      }
    }

    @include mobileLarge {
      padding-top: 13.2rem;
    }

    @include mobileMedium {
      padding-top: 13.7rem;
    }
  }

  .slideWrapper {
    & > div:nth-child(2) {
      border-top: 0.1rem solid $border-light;
    }

    .hasValues::after {
      content: '€';
    }
  }

  .name {
    padding-bottom: 3rem;
  }

  :global {
    .slick-slide:nth-child(5) {
      a {
        background: $secondary-accent;
        color: $forth-color;
        border-color: $secondary-accent;
      }
    }

    .slick-slide:nth-child(4) {
      div {
        color: $primary-color;
      }

      & > div > div > div:nth-of-type(1) {
        color: $default-color;
      }

      a {
        background: $gradient;
        color: $forth-color;
        border-color: transparent;
      }
    }

    .slick-slide:nth-child(3) {
      & > div > div {
        background: $third-color;
      }

      div::before,
      div {
        color: $forth-color !important;
        opacity: 1;
      }

      a {
        background: $forth-color;
        color: $third-color;
        border-color: $forth-color;
      }
    }
  }
}

.additionalBtnWrapper {
  @include size($width: 100%, $max-width: 164rem);
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}

button.additionalOptions {
  @include font($color: $secondary-color, $font-size: 1.4rem, $font-weight: $bold);
  padding: 0 0 0 1.5rem;
  margin-top: -7rem;
  z-index: 8;

  &:hover {
    color: $third-color;
  }

  @include mobileMedium {
    max-width: 13rem;
    text-align: left;
  }
}

.section {
  position: relative;
  padding-bottom: 0;
  background-repeat: no-repeat;
}

.singularSlider {
  .labelsBlock {
    padding-right: 0;

    @include mobileLarge {
      width: 20rem;
    }

    @include mobileMedium {
      width: 12rem;
    }
  }

  .pricingSlider {
    width: calc(100% - 28rem);

    @include mobileLarge {
      width: calc(100% - 20rem);
    }

    @include mobileMedium {
      width: calc(100% - 12rem);
    }

    .cta {
      display: none;
    }

    button {
      top: 4.7rem;

      @include mobileMedium {
        top: 7.8rem;
      }

      &::before {
        @include font($font-weight: 900, $font-family: $fontAwesome, $color: $primary-color);
      }

      &:first-of-type {
        z-index: 7;
        left: 3.5rem;

        @include mobileLarge {
          left: -2rem;
        }

        @include mobileMedium {
          left: -1.5rem;
        }

        &::before {
          content: $prev-icon;
        }
      }

      &:last-of-type {
        z-index: 7;
        right: 3.5rem;

        @include mobileLarge {
          right: -2rem;
        }

        @include mobileMedium {
          right: -1.5rem;
        }

        &::before {
          content: $next-icon;
        }
      }
    }
  }

  .slideWrapper {
    box-shadow: none;
    margin-left: 26rem;
  }
}

.itemWithAttr {
  position: relative;
  opacity: 1;
  text-align: center;

  &.name {
    font-weight: bold;
  }

  @include mobileLarge {
    font-size: 1.2rem;
  }

  &.typeOfPrograms,
  &.setup {
    color: $accent-color;
  }

  &::before {
    @include position(absolute, null, 100%);
    @include font($font-size: 1.4rem, $color: $default-color, $font-weight: 700, $text-align: left);
    content: attr(data-label);
    width: 90%;

    @include mobileLarge {
      font-size: 1.2rem;
      padding-right: 1.5rem;
    }
  }
}

.staticSubscriptionWrapper {
  @include size($max-width: 100%, $width: auto);
}
