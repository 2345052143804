@import '../imports';

$pageNotFoundCodeColor: #1c1c1c;

.pageNotFound {

  &Anonymous {
    @extend .totalCenter;
    @include size($width: 100vw, $min-height: 100vh);
  }

  &Logged {
    @extend .totalCenter;
    @include size($width: 100%, $min-height: calc(100vh - 14rem));
  }

  &Inner {
    padding: 1.5rem;
    width: 100%;
    text-align: center;
  }

  &Content {
    @include font($font-family: $default-font, $text-align: center);
    position: relative;
    width: 100%;
  }

  &Code {
    @include mobileLarge {
      font-size: 10rem;
    }
    @include font($color: rgba($pageNotFoundCodeColor, .06), $font-size: 18.3rem, $font-weight: $bold);
  }

  &Message {
    @include mobileLarge {
      font-size: 2.2rem;
    }
    @include font($color: $default-color, $font-size: 4.2rem, $font-weight: $bold);
    @include position(absolute, 50%, 0, null, 0);
    @include prefix(transform, translateY(-50%));
  }

  &Image {
    max-width: 55rem;
    display: block;
    margin: 0 auto 5rem;
  }
}
