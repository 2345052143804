@import '../../imports';
.header{
  &Width{
    &24{
      width: 24rem;
    }
    &15{
      width: 15rem;
    }
  }
}
.gridPoints{
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 7% 7% 86%;
  grid-template-columns: 100%;
  column-gap: 1rem;
  margin-bottom: 1rem;
  &Filter{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;
    row-gap: 0.5rem;
    // margin-bottom: 1rem;
  }
}

.gridPointsHeight{
  height: 82vh !important;
}
.gridPoints div{
  // max-height: 40px;
}
.pointsTabList{
  display: flex;
  column-gap: 2rem;
  margin-bottom: 2rem;
}
.gridPoints button{
  height: 40px;
}
div[class*="optionListContainer"]{
  z-index: 600 !important;
}
/*
Tooltip style
*/
.custom-tooltip {
  color: var(--ag-foreground-color);
  background-color: #5577cc;
  padding: 5px;
}

.custom-tooltip p,
.custom-tooltip h3 {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.tooltip{
  // --left-width: calc((100%-1182px)*-1);
  // transform : translate(calc(var(--left-width)), 40%);
  position: fixed;
  // transform: translateY(40%);
  border: 1.5px solid $primary-color;
  padding: 10px;
  border-radius: 5px;
  z-index: 99999;
  // min-width: 200px;
  // min-height: 100px;
  &Container{
    position: relative;
    display: inline-block;
    color: white;
    font-weight: 500;
  }
  background-color: $secondary-color;
  color: white;
  font-weight: 500;
  display: grid;
  grid-template-areas: "textCell btnCell"
                        "btnRow btnRow";
  grid-row-gap: 5px;
  column-gap: 5px;
                        // grid-template-columns: 75% 25%;
  // grid-template-rows: 100%;
  align-items: center;
  justify-content: center;
}
.textCell{
  grid-area: textCell;  
}
.btnCell{
  grid-area: btnCell;
}.btnRow{
  grid-area: btnRow;
}
