//Helper classes used for spacing
//Info: m stand for margin and p for padding
// t stand for top and b for bottom
//the values are in rem, 1rem = 10px
//if you need for example a margin top of 35px that will be .mt35
// the values for integers are 1, 2, 3, 4, 5 etc, that is equal to 10px, 20px, 30px, 40px, etc
// the values for floats are 15, 25, 35, 45, 55, etc, that is equal to 15px, 25px, 35px, 45px, etc
//Usage example: .mt10
@each $key, $value in $spacings {
  //Margins
  .m#{$key} {
    margin: $value !important;
  }
  .mt#{$key} {
    margin-top: $value !important;
  }
  .mb#{$key} {
    margin-bottom: $value !important;
  }
  .ml#{$key} {
    margin-left: $value !important;
  }
  .mr#{$key} {
    margin-right: $value !important;
  }
  .mx#{$key} {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .my#{$key} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
  //Paddings
  .p#{$key} {
    padding: $value !important;
  }
  .pt#{$key} {
    padding-top: $value !important;
  }
  .pb#{$key} {
    padding-bottom: $value !important;
  }
  .pl#{$key} {
    padding-left: $value !important;
  }
  .pr#{$key} {
    padding-right: $value !important;
  }
  .py#{$key} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
  .px#{$key} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  .width#{$key} {
    width: $value !important;
  }

  .maxWidth#{$key} {
    max-width: $value !important;
  }

  .minWidth#{$key} {
    min-width: $value !important;
  }

  .minHeight#{$key} {
    min-height: $value !important;
  }

  .height#{$key} {
    height: $value !important;
  }
}

.mxAuto {
  margin-left: auto;
  margin-right: auto;
}

.mtAuto {
  margin-top: auto;
}

.height100 {
  height: 100%;
}

.width45Percent {
  width: 45%;
}

.widthFull {
  width: 100%;
}

  //iterating on the breakpoints
@each $media, $breakpoint in $breakpoints-max-sizes {

  //create media query for all breakpoints
  @media (max-width: $breakpoint) {

    @each $key, $value in $spacings {
      .#{$media}P#{$key} {
        padding: $value !important;
      }

      .#{$media}PTop#{$key} {
        padding-top: $value !important;
      }

      .#{$media}Px#{$key} {
        padding-left: $value !important;
        padding-right: $value !important;
      }

      .#{$media}Pl#{$key} {
        padding-left: $value !important;
      }


      .#{$media}Mb#{$key} {
        margin-bottom: $value !important;
      }

      .#{$media}Mt#{$key} {
        margin-top: $value !important;
      }

      .#{$media}Ml#{$key} {
        margin-left: $value !important;
      }

      .#{$media}MinH#{$key} {
        min-height: $value !important;
      }
    }

    .#{$media}WidthFull {
      width: 100% !important;
    }

    .#{$media}MaxWidthFull {
      max-width: 100% !important;
    }

    .#{$media}h60vh {
      max-height: 60vh !important;
    }

    .#{$media}h53vh {
      max-height: 53vh !important;
    }
    //also you can add another each here in order to generate more responive classes
    .#{$media}AlignItemsCenter {
      align-items: center;
    }

    .#{$media}JustifyContentCenter {
      justify-content: center;
    }
  }
}
