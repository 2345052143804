@import '../../imports';
@import '../../utils/constants';

.fieldBlock {
  &Wrapper {
    @include mobileLarge {
      justify-content: space-between;

      & > div {
        @include size($width: calc(50% - .5rem));
        margin-bottom: 1rem;
      }
    }
    @include display($display: flex, $flex-direction: row, $flex-wrap: wrap);
    margin-top: 3rem;
    margin-bottom: 4rem;
    min-height: 17rem;
  }

  &Element {
    @extend .totalCenter;
    @include size($width: 19rem, $height: 8.4rem);
    @include font($font-size: 1.6rem, $color: $menu-accent);
    @include mobileLarge {
      @include size($max-width: 19rem, $width: 100%, $height: 7rem);
      font-size: 1.5rem;
      margin: 0;
      padding: 1rem;
    }

    padding: 1rem 3rem;
    text-align: center;
    border-radius: .7rem;
    margin: 1rem;
    border: .1rem solid $border-medium;
  }

  &ElementActive {
    background: $menu-accent;
    border-color: $menu-accent;
    color: $forth-color;
    cursor: not-allowed;
  }

  &CustomisableElement {
    cursor: pointer;
    transition: .2s;

    &:hover {
      border: .1rem solid $accent-color;
      box-shadow: $light-shadow;
    }

    &:active {
      box-shadow: none;
    }
  }

  &ElementDisabled {
    pointer-events: none;
    background: $forth-color;
    color: $border-light;
    border-color: $border-light;
  }
}

.fieldBlockElementActive.fieldBlockCustomisableElement {
  &:after {
    @include font($font-weight: $bold, $font-size: 1.5rem, $font-family: $fontAwesome, $color: $forth-color);
    @include position(absolute, 0, .5rem, null, null);
    content: $close-icon;
  }
}
