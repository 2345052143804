@import "../../imports";

.userDeclarationBlock {
  @extend .userBlockBase;
  @include respond-to-max-height('tablet-landscape-height') {
    height: 28.5vh;
  }
  @include respond-to-max-height('custom-resolution-2') {
    height: 30vh;
  }
  margin: 1.5rem auto;
  text-align: center;
  height: 24.1vh;
  position: relative;
  overflow: hidden;

  &Title {
    @include font($font-weight: map_get($font-weights, 'bold'), $color: $gray-accent-color);
    width: 98%;
  }

  &Link {
    width: 100%;
    color: $primary-color;
  }

  &Freemium {
      @include respond-to-max-height('tablet-portrait-height') {
        font-size: 3.5rem;
      }
      @include font($font-weight: map_get($font-weights, 'bold'), $font-size: 4.5rem, $color: $secondary-color, $line-height: 1);
      width: 100%;
  }
}

.addDeclaration {
  [class*='Input_datepicker'] {
    margin-bottom: 1rem !important;
    @extend .flex-center-vertical;

    label {
      padding: 0 1.2rem;
      font-size: 1.8rem;
      font-weight: map_get($font-weights, 'light');
      margin: 0;
    }
  }

  [class*='style_displayFlex'] {
    justify-content: center;
  }
}

.customCheckboxInput {
  display: inline-block;
  padding-top: 1rem;
  cursor: pointer;
  padding-right: 2rem;

  &Active {
    font-weight: map_get($font-weights, 'bold');
  }

  &Icon {
    margin-right: 0.5rem;
  }
}

.customSelect {
  margin: 1rem 0;
}

.disabled {
  opacity: 0.4;
}

.userDeclarationForm {
  @include respond-to-max-height('custom-resolution-5') {
    max-height: 48rem;
    overflow-y: auto;
  }
  @include respond-to-max-height('custom-resolution-4') {
    max-height: 40rem;
  }
  form {
    @include respond-to-max('mobile-large') {
      padding: 0 1.5rem;
    }
    padding: 0 10rem;
    [class*="style_displayFlex_"] {
      label {
        padding-bottom: 1rem;
      }

      & > div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      [class*="Input_error"] {
        bottom: -2.2rem;
      }

      [class*="Button_btn"] {
        margin-bottom: 1rem;
      }
    }

    [name="additionalComments"] {
      width: 100%;
      height: 6rem;
      padding: 1rem 1.5rem;
      border: none;
      border-bottom: 0.1rem solid #6B6B6B;
      resize: vertical;
      min-height: 5rem;
    }

    [class*="Input_floating"],
    [class*="Input_datepicker"] {
      max-width: 100% !important;
    }

    [class*="customInputField"] {
      span {
        width: 100%;
      }
    }
    [class*="customFieldWrapper"] {
      label {
        flex: 0 0 40%;
      }
    }
  }
}

.createDeclarationCustom {
  &Wrapper {
    padding: 0 0 2rem 0;

    [class*='Table_tableHeaderResponsiveMobile'] {
      height: 10rem;
    }

    [class*='Input_datepicker'] {
      position: relative;
      padding: 0;
      label {
        @include respond-to-max('mobile-large') {
          margin-right: 0
        }
        margin-right: 5rem;
      }
    }
  }

  &Input {
    font-family: $primary-font;
    font-size: map_get($fontSizes, 'Md');

    [class*='Navbar_container'] {
      & > div {
        width: 100%;
      }
    }
  }
}
.userDeclarationBlock div[class*="postMediaContainer"] {
  height: 75% !important;
}
