@import '../../imports';
@import "../../layout/fixedBlock.module";

.wallBaseStructure {
  @include respond-to-max(desktop-small) {
    @include display-flex($flex-direction: column, $align-items: center);
  }
  @include respond-to-max(tablet-landscape) {
    margin-top: 10rem;
  }
  @extend .flex-start;
}

.hideBlockMobile {
  @include respond-to-max('desktop-small') {
    display: none !important;
  }
}

.baseColumnBlock {
  @include respond-to-max('desktop-small') {
    @include size($width: 95%, $max-width: 95%);
    margin: 0 auto;
    padding: 0;
  }
  z-index: 2;
  flex: 0 0 23.5%;
  display: block;
  position: relative;
}

.wallCenterBlock {
  @include respond-to-max('desktop-small') {
    @include size($width: 100%, $max-width: 100%);
    flex: 0 0 100%;
  }
  @extend .flex;
  @extend .flex-direction-column;
  flex: 0 0 53%;
  max-width: 53%;
  z-index: 3;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1rem 2rem;

  > div {
    width: 100%;
  }

  .postsSummary {
    @extend .flex-center-total;
    @include border-radius(2rem);
    @include border($border-color: $gray-lighter-color, $border-width: 0.1rem, $border-style: solid);
    @include size($width: 100%, $max-width: 100%, $height: auto);
    background-color: $default-color;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;
    box-shadow: $shadow-tight-1;

    &Title {
      @include font($font-size: 3rem, $color: $secondary-color, $font-weight: 700);
      @include respond-to-max('desktop-small') {
        margin-top: 2rem;
        font-size: 2rem;
        padding: 0;
      }
      padding: 1rem 0;
    }

    &Content {
      @include respond-to-max('desktop-small') {
        text-align: center;
        padding: 2rem;
      }
      font-size: 1.4rem;
      color: $primary-light-color;
      padding: 0 1.5rem;
    }
  }
}


.wallSingleWidget {
  @include respond-to-max('desktop-small') {
    width: 90%;
  }
  width: 70%;
  margin-right: 1.5rem;
}

.customAnimation {
  -webkit-animation: bummer .3s;
  animation: bummer .3s;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bummer {
  100% {
    -webkit-transform: scale(0, 0);
  }
}

@keyframes bummer {
  100% {
    transform: scale(0, 0);
  }
}
