// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  font-size: 10px;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-overflow-scrolling: touch;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

*:focus {
  outline: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}
