@import '../imports';
@import '../utils/constants';

.expiredLink {
  &Container {
    @include size($width: 100vw, $height: 100vh);
    @include display($display: flex, $align-items: center, $justify-content: center, $flex-wrap: wrap);
    background: $logo-gradient;
    text-align: center;
    padding: 0 1.5rem;

    img {
      display: block;
      margin-bottom: 2rem;
      max-width: 20rem;

      @include mobileLarge {
        margin-bottom: 1rem;
      }
    }

    button {
      display: inline-block;
    }
  }

  &Title {
    color: $forth-color;
    margin-bottom: 3rem;
  }

  &Content {
    @extend .totalCenter;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
