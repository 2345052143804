@import '../../imports';

.formWrapper {
  @include size($width: 100%, $max-width: 40rem);
  padding: 1.5rem;

  label {
    font-size: 1.6rem;
  }
}

.backToLanding {
  @include respond-to-orientation('landscape') {
    top: 2rem;
  }
  @include position(absolute, 3rem, null, null, 3rem);

}

.userActionPage {
  padding-top: 5rem;
  min-height: 100vh;
  height: 100vh;
}
