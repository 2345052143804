@import "../../imports";

.settingPersonalInformations {
  padding-top: 3rem;

  form {

    [class*="Modal_inputsWrapper_"],
    [class*="Input_floating"],
    [class*='Input_datepicker'] {
      max-width: 100% !important;

      label {
        display: block;
      }
    }

    [class*='Input_datepicker'] {
      display: flex;
      border-bottom: 0.1rem solid $border-dark-color;
      margin-top: 0 !important;
      margin-bottom: 1rem;
      padding-bottom: 2.5rem;

      label {
        margin-right: 2rem;
        align-self: center;
        margin-bottom: 0;
      }
    }

    [class*="Input_civility_"] {
      padding-left: 0 !important;

      @include respond-to-max('mobile-large') {
        label {
          display: block;
          margin-right: auto;
        }
      }
    }

    @include respond-to-max('mobile-large') {
      button {
        margin-bottom: 1rem;
      }
    }
  }

  .btnDelete {
    padding-left: 1rem;
  }

  .inputError {
    @include position(absolute, null, 0, .8rem, 0);
    @include font($color: $danger-color, $font-size: 1.2rem, $text-align: center);
  }

  .uploadImage {
    border-bottom: .1rem solid $border-dark-color;
    margin-bottom: 2.5rem;
  }
}

.addChip {
  @include size($width: 6rem);
  @include font($color: $primary-color);
  @include display-flex($justify-content: flex-end);
  align-self: flex-end;
  cursor: pointer;

  svg {
    margin-right: 1rem;
  }

  &Disabled {
    @include font($color: $gray);
    cursor: not-allowed;
  }
}

.addEmailContainer {
  display: grid;
  grid-template-areas:
    "label chip add"
    "input input input"
    "error error error";
  /* justify-content: normal; */
  width: 100%;
  align-items: center;
}

.addEmailContainer .chipEmail {
  grid-area: chip;
}

.addEmailContainer .chipEmail div {
  padding: 0.5rem 1rem;
  background-color: white !important;
  border-radius: 1.5rem;
  display: inline-block;
  margin: 0.5rem;
  border: 1.5px solid #3e216b !important;
  box-shadow: none;
}

.addEmailContainer .chipEmail div span {
  color: #3e216b !important;
}

.inputEmail {
  grid-area: input;
  width: 100%;
}

.customAddChip {
  grid-area: add;
  width: 100%;
  align-items: center;
  display: flex;
  height: 100%;
}

.errorEmail {
  grid-area: error;
  width: 100%;
}

.customLabelInput {
  grid-area: label;
  display: flex;
  align-items: flex-start;
  margin-left: 1.75rem;
}