@import "../../imports";

.notificationList {
  min-width: 70rem;
  min-height: 65rem;
  padding: 3rem 2rem 0;

  [class*='NotificationDropdown_notificationsDropdownItemContent'] {
    border: none;
  }

  &Wrapper {
    display: inline-block;
    width: 70%;
  }

  &Container {
    border-bottom: 1px solid $gray-light-accent-color;
  }

  &Header {
    @include display-flex($justify-content: space-between);
    border-bottom: 1px solid $gray-light-accent-color;
    padding: 0 5rem 0;

    &Item {
      font-weight: map_get($font-weights, 'bold');
      cursor: pointer;
      color: $gray-accent-color;
      padding: 1rem .5rem;
      position: relative;

      &.active {
        color: $default-text-color;
        border-bottom: 2px solid $secondary-color;
      }
    }
  }
}

.badge {
  @include position($position: absolute, $top: .275rem);
  @include size($width: 1.75rem, $height: 1.75rem);
  @include font($font-size: 1rem, $color: $default-color, $font-weight: map_get($font-weights, 'light'));
  border-radius: 50%;
  background-color: $danger-color;
  text-align: center;
  line-height: 1.75rem;

  &.dropdownIcon {
    @include size($width: 1.5rem, $height: 1.5rem);
    font-size: 1rem;
    top: 1rem;
    right: -.5rem;
  }
}

