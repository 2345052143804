@import '../../imports';
@import '../../utils/constants';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/regular.css';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/brands.css';
@import '../../../../../node_modules/@fortawesome/fontawesome-free/css/solid.css';


.topNavigationWrapper {
  @include tabletLandscape {
    justify-content: center;
    @include display($align-items: center, $justify-content: flex-end);
    @include position(fixed, 0, 0, null, 0);
    box-shadow: none;
    padding: 2rem 1rem 2rem 6rem;
    background: $forth-color;
    z-index: 9;
  }
  min-height: 7.5rem;
  display: flex;
  position: relative;
}

.topControls {
  @extend .horizontalCenter;
  @include position(fixed, 1.5rem, 1.5rem, 0, null);
  z-index: 999;
  align-items: center;
  @include tabletLandscape {
    @include position(relative, 0, 0, 0, null);
  }
  height: 3.5rem;
  a {
    @include mobileLarge {
      margin: 0 .5rem;
    }
    margin: 0 1rem;

    &:hover {
      svg {
        @include prefix(transform, scale(1.1), webkit moz ms o);
      }
    }

    svg {
      @include mobileLarge {
        font-size: 1.4rem;
      }
      @include prefix(transition, transform 0.3s, webkit moz ms o);
      font-size: 1.8rem;
    }
  }
}
