@import '../../imports';

.userList {
  width: 50%;
  padding-bottom: 3rem;

  @include desktopSmall {
    width: 100%;
  }

  @include mobileMedium {
    padding-bottom: 1rem;
  }

  &Accepted {
    &title {
      font-weight: $bold;
      padding-right: 0.5rem;
    }
  }

  &Title {
    @include font($color: $default-color, $text-align: center, $font-size: 2.5rem, $font-weight: $bold);
    margin: 1.5rem 0;

    @include mobileMedium {
      font-size: 1.5rem;
      padding: 1.5rem 0;
    }
  }

  &Dropzone {
    @include display(flex, center, center);
    margin: 2rem 0 1.5rem 0;
    height: 22rem;
    border: 0.15rem dashed $menu-accent;

    @include mobileMedium {
      height: 15rem;
      border-width: 0.1rem;
    }

    &Active {
      border-color: $secondary-accent;
      border-width: 0.3rem;
    }

    &Inside {
      @include font($font-size: 1.6rem, $font-weight: $bold, $color: $menu-accent);
      position: relative;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        transform: scale(1.05);
      }

      &:before {
        @include font(
                $font-weight: $bold,
                $font-size: 1.3rem,
                $font-family: $fontAwesome,
                $color: $menu-accent,
                $text-align: center
        );
        @include position(absolute, -2rem, null, null, null);
        content: $file-icon;
        width: 100%;
      }
    }

    &Title {
      @include font($font-size: 1.6rem, $font-weight: $bold, $color: $menu-accent);

      &Error {
        @extend .userListDropzoneTitle;
        color: $danger;
      }
    }

    .Loader {
      padding-top: 0;
      color: $menu-accent;
    }
  }
}

.templateDownloadList {
  @include display(flex, center);

  .listItem {
    color: $primary-color;
    background: $forth-color;
    border: 0.1rem solid $primary-color;
    border-radius: 0;
    padding: 0.6rem 1rem;
    margin: 0 1rem 3rem 1rem;

    &:hover {
      background: $menu-accent;
      color: $forth-color;
      border-color: $menu-accent;
      box-shadow: $light-shadow;
    }
  }
}

.userListResult {
  @extend .userListDropzone;
  @include display(flex, center, center, $flex-direction: column);
  @include font($text-align: center, $font-size: 1.6rem);

  .userListModify {
    margin: 0 1rem;
    background: $danger;
    color: $forth-color;
    border-color: $danger;

    &:hover {
      box-shadow: $light-shadow;
    }
  }

  .userListProceed {
    margin: 0 1rem;
    background: $menu-accent;
    color: $forth-color;
    border-color: $menu-accent;

    &:hover {
      box-shadow: $light-shadow;
    }
  }

  &Contacts {
    font-weight: $bold;
  }

  &Buttons {
    @include display(flex, space-between, center);
    margin-top: 5rem;
  }
}

.userUploadContainer {
  display: flex;

  @include desktopSmall {
    flex-direction: column;
    align-items: center;
  }
}

.userListResults {
  padding: 0 5rem;
}
