@import '../../imports';

.baseList {
  @include size($width: 100%);
}

.addPostButton {
  padding-right: 1rem;
  padding-left: 1rem;
}

.basePostType {
  @include size($width: 50%, $height: .5rem);
  display: block;
  content: '';
  transition: transform .3s;
}

.lineStrike {
  @extend .basePostType;
  z-index: 0;

  &Container {
    @include position(absolute, 0, 0, 0, 0);
    pointer-events: none;
    border-radius: 1.5rem;
    overflow: hidden;
  }
}

.post {
  &Placeholder {
    @include size($width: 100%, $height: 30rem);
    @include respond-to-max('mobile-large') {
      height: 10rem;
    }
  }

  &MediaContainer {
    @include respond-to-max('mobile-large') {
      padding-right: 0;
    }
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 1rem;
    word-wrap: break-word;
    overflow: hidden;

    img {
      width: auto;
    }
  }

  &Content {
    @include respond-to-max('mobile-large') {
      @extend .flex-center-total;
      padding-right: 0 !important;
    }

    &Order {
      @include display-flex($flex-direction: column);
      @include respond-to-max('mobile-large') {
        flex-direction: column;
      }
    }

    &Text {
      @include respond-to-max('mobile-large') {
        padding-left: 0;
      }
    }
  }

  &AuthorDetail {
    padding-right: 8rem;
    margin-bottom: 1.5rem;
  }

  &AuthorizeIcon {
    @include respond-to-max('mobile-large') {
      right: 2rem;
    }
    @include position(absolute, 1.5rem, 4rem);
    @include font($font-size: 1.2rem, $color: $gray-accent-color);
  }

  &ActionBlock {
    @include respond-to-max('mobile-large') {
      margin-right: .5rem;
    }
    @include size($max-width: 40%, $max-height: 3rem);
    overflow: hidden;
  }

  &Block {
    background-color: $default-color;
    @include respond-to-max('mobile-large') {
      padding: 1.5rem;
    }
    padding: 3rem;
    border-radius: 1.5rem;
    box-shadow: $shadow-tight-1;
    position: relative;

    &Wrapper {
      margin-bottom: 1.5rem;
      word-break: break-word;
    }

    &Express .lineStrike {
      @include position(absolute, 0, null, null, 0);
      background: $secondary-color;
    }

    a {
      color: $secondary-color;
    }

    &Task{
      a{
        color: $primary-color
      }
    }

    &Task .lineStrike {
      @include position(absolute, 0, 0);
      background: $primary-color;
    }

    &IsLikedBtn {
      cursor: pointer;

      &Express {
        color: $secondary-color;
      }

      &Task {
        color: $primary-color;
      }
    }

    &Disabled {
      pointer-events: none;
    }

  }
}

.postCTA{
  padding: .4rem 1.8rem;
}

.likes {
  background-color: rgba($default-text-color, .9);
  display: block !important;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 0;
  color: $default-color;

  &Back {
    width: 100%;

    &Btn {
      @include size($width: 3rem, $height: 3rem);
      padding: 0;
      border: none !important;
      align-self: flex-start;
      background: transparent;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;

      &::after {
        @include position(absolute, 50%);
        @include font($font-size: 3rem, $text-decoration: none, $line-height: 1);
        display: inline-block;
        transform: translateY(-50%) rotate(180deg);
        content: $left-arrow-icon-unicode;
      }
    }
  }
}

.likesList {
  @include font($text-align: left);
  align-self: flex-start;

  li {
    :last-child{
      padding-left: 1rem;
    }
  }

}

.express {
  @include font($color:  $secondary-color);
}

.task {
  @include font($color:  $secondary-color);
}
