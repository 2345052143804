@import '../../imports';

.wallPost {
  width: 100%;

  &Disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &UploadContainer {
    margin-bottom: -.8rem;
  }

  &ConfidentialityUpload {
    @include respond-to-max('desktop-small') {
      left: 0;
      right: unset;
      align-items: flex-start;
      margin-bottom: 1rem;
      position: relative;
    }
    @include display($display: flex, $flex-direction: column, $align-items: flex-end);
    @include font($color: #868586);
    @include position($position: absolute, $top: .5rem, $right: 2rem);

    :first-child {
      cursor: pointer !important;
      flex-wrap: nowrap;
      -ms-flex: auto;

      .IndicatorContainer {
        padding: 0 !important;
      }
    }
  }

  &ConfidentialityCreate {
    position: inherit !important;
  }

  &FilePreview {
    @include display($flex-direction: column);
    @include size($width: 100%, $max-width: 20rem);
    @include font($font-size: 1.4rem, $color: $gray-accent-color);
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 1.2rem;
    word-wrap: break-word;
    box-shadow: $shadow-light;

    svg {
      color: $default-text-color;
      cursor: pointer;
      align-self: flex-end;
    }
  }

  &Icon {
    @include size($width: 3.3rem, $height: 3.3rem);
    border: .1rem solid $default-color;
    box-shadow: $shadow-light;
    border-radius: 2rem;
    position: relative;
    overflow: hidden;

    img {
      @include force_center(absolute);
      width: 100%;
    }
  }

  &Title {
    width: 100%;

    & > div {
      max-width: none;
      padding-bottom: 0;
    }

    input {
      @include font($font-weight: map_get($font-weights, 'bold'), $color: $gray-accent-color);
      border: none;
      background: none;

      &::placeholder {
        color: $gray-light-accent-color;
      }
    }
  }

  &Info {
    @extend .flex;
  }

  img {
    max-width: 100%;
  }

  &Content {
    margin-bottom: 1.5rem;

    textarea {
      @include font($font-size: 1.4rem, $color: $gray-accent-color);
      border: none;
      background: none;
      height: 9rem;

      &::placeholder {
        color: $gray-light-accent-color;
      }
    }
  }

  &PublishPost {
    margin-left: auto;

    span {
      background: $secondary-color;
    }
  }

  &PublishTask {
    @include respond-to-max('mobile-large') {
      margin-left: unset;
    }
    margin-left: auto;

    span {
      background: $primary-color;
    }
  }

  &Actions {
    @include respond-to-max('mobile-large') {
      flex-wrap: wrap;
      > span {
        display: none;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;

    &Publish {
      @include respond-to-max('mobile-large') {
        width: 100%;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 2rem;
    }
  }

  &ActionBlock {
    color: $gray-light-accent-color;
    cursor: pointer;
    display: flex;
    [class*="container"]{
      min-width: 7.5rem;
    }

    :global .css-1okebmr-indicatorSeparator {
      display: none;
    }

    :global .react-datepicker-wrapper {
      margin-right: 1.5rem;
    }

    &:hover {
      color: $gray;
    }

    svg {
      margin-right: .5rem;
    }
  }

  &Pinned {
    color: $gray;
  }

  &Option {

    &:hover {
      background-color: $gray-light-accent-color;
      cursor: pointer;
    }

    &Selected {
      @include font($color: $gray !important);
      background-color: $gray-lighter-color !important;
      cursor: default !important;
    }
  }
}

.taskDatePicker {
  display: flex;

  &Input {
    color: $gray-light-accent-color;
    transition: all .3s ease;

    &:hover {
      color: $gray;
    }
  }
}
