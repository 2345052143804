@import '../imports';
@import '../../stylesheets/abstracts/checkboxes';

.design {
  &CoverUpload {
    @include respond-to-max('mobile-large'){
      @include size($width: 10rem, $height: 10rem);
      @include position($right: 1rem, $bottom: 1rem);
      padding-left: .5rem;
      padding-right: .5rem;
      
    }
    @include size($width: 15rem, $height: 15rem);
    @include position(absolute, null, 1.5rem, 1.5rem, null);
    @extend .flex-center-total;
    @extend .flex-direction-column;
    z-index: 1;
    border: .1rem dashed $border-dark-color;
    padding: .5rem 1.6rem;
    text-align: center;
    background: $default-color;

    p {
      @include respond-to-max('mobile-large'){
        @include font($font-size: .7rem, $line-height: 1rem);
      }
      @include font($font-size: .9rem, $color: $gray-accent-color, $line-height: 1.4rem);
    }

    :global {
      .fileContainer {
        width: auto;
        display: inline-block;
        box-shadow: none;
        padding: 0;
        cursor: pointer;

        input {
          width: .1rem;
        }
      }

      button.chooseFileButton {
        @include respond-to-max('mobile-large'){
          font-size: 1.4rem;
        }
        @include respond-to-max('mobile-large'){
          font-size: 1.2rem;
        }
        @include font($font-size: 1.6rem, $color: $gray-accent-color !important);
        padding: 0;
        margin: 0;
        
        &::before {
          @include respond-to-max('mobile-large'){
            font-size: 2rem;
            margin-bottom: 0;
          }
          @include font( $font-weight: map_get($font-weights, 'bold'), $font-size: 3rem, $font-family: $font-awesome, $color: $gray-accent-color);
          display: block;
          margin-bottom: 1rem;
          content: $plus-icon;
        }

        &:hover::before {
          color: $menu-accent
        }
      }
    }
  }

  &UploadValue {
    @include respond-to-max('mobile-large'){
      @include position($right: 0, $bottom: 10.5rem);
      max-width: 10rem;
    }
    @include position(absolute, null, 16rem, 0, null);
    border: .1rem dashed $border-dark-color;
    background: $default-color;
    padding: .5rem;
    word-break: break-all;
    max-width: 15rem;

    button {
      @include respond-to-max('mobile-medium'){
        margin-top: 0;
      }
      background: $gradient;
      border: none;
      margin-top: .5rem;
      width: 100%;
    }

    svg {
      @include font($font-size: 1.1rem, $color: $default-color);
    }

    span {
      @include respond-to-max('mobile-medium'){
        display: none;
      }
    }
  }

  &IdentificationWrapper {
    @include respond-to-max('desktop-small'){
      height: 45rem;
    }
    @include respond-to-max('tablet-portrait'){
      height: 30rem;
    }
    @include respond-to-max('mobile-large'){
      flex-direction: column;
      height: auto;
    }
    @include size($height: 71rem, $width: 100%, $max-width: 117rem);
    @extend .flex-center-total;
    margin: 0 auto 4rem;
    justify-content: space-between;
    border: .1rem solid $border-dark-color;
    position: relative;
  }

  &IdentificationText {
    padding-bottom: 2rem;
  }

  &IdentificationInfo {
    @include respond-to-max('desktop-medium') {
      max-width: 50%;
    }
    @include respond-to-max('desktop-small'){
      max-width: 50%;
    }
    @include respond-to-max('tablet-portrait'){
      max-width: 100%;
    }
    @include respond-to-max('mobile-large'){
      max-width: 100%;
      padding: 0 1rem;
    }
    @include respond-to-max('mobile-medium'){
      max-width: 100%;
    }
    @include size($max-width: calc(100% - 65rem), $width: 100%);
    padding: 0 4.5rem;

    input, input::placeholder {
      @include respond-to-max('desktop-medium') {
        font-size: 3vw;
      }
      @include respond-to-max('desktop-small'){
        font-size: 2.5vw;
      }
      @include respond-to-max('tablet-portrait'){
        font-size: 3.2rem;
      }
      @include respond-to-max('mobile-large'){
        font-size: 2.3rem;
      }
      @include font($font-size: 4rem, $color: $secondary-color, $font-weight: map_get($font-weights, 'bold'));
    }

    & > div {
      position: relative;
    }
  }
  
  &CoverWrapper {
    @include size($height: 100%, $width: 100%);
    position: relative;
    overflow: hidden;
    
    img {
      @include vertical_center(absolute);
      @include size($width: 100%, $height: 100%);
    }
  }
  
  &PreviewBlock {
    @include respond-to-max('desktop-medium') {
      width: 50%;
    }
    @include respond-to-max('desktop-small'){
      height: 45rem;
    }
    @include respond-to-max('tablet-portrait'){
      height: 30rem;
      width: 100%;
    }
    @include respond-to-max('mobile-large'){
      @include size($height: 20.8rem, $width: 100%);
    }
    @include respond-to-max('mobile-medium'){
      height: 16rem;
    }
    @include size($height: 100%, $width: 65.4rem);
    border-left: .1rem solid $border-dark-color;
  }

  &Textarea {
    @include respond-to-max('tablet-portrait'){
      height: 20rem;
    }
    @include respond-to-max('mobile-large'){
      height: 15rem;
      font-size: .9rem;
      padding: 1rem;
    }
    @include respond-to-max('mobile-medium'){
      height: 11em;
    }
    @include size($width: 100%, $height: 25rem);
    border: .1rem solid $gray-light-accent-color;
    border-radius: 1rem;
    font-size: 1.3rem;
    resize: none;
    padding: 2rem;
    background: url('#{$img-path}/pen.png') no-repeat 97% 95% $default-color;
  }
}
