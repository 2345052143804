@import '../../imports';

.generalWallStructure {
  @include tabletLandscape {
    padding-left: 3rem;
  }

  @include mobileLarge {
    padding-left: 5rem;
  }

  @include mobileMedium {
    padding-left: 2rem;
    margin-bottom: 4rem;
  }

  padding: 9rem 2rem 8rem 14rem;
  position: relative;

  form {
    width: 100%;
  }
}

.containerSmall {
  @include mobileLarge {
    max-width: none;
  }
  width: 100%;
  margin-top: 8rem;
}

.containerLarge {
  width: 100%;
  margin-top: 4rem;
}

.formLarge {
  & > div, & > p {
    @include mobileLarge {
      @include size($width: 100%, $max-width: none);
    }
    @include size($max-width: 60.8rem, $width: 100%);
  }
}

.accentLabels {
  label {
    @include font($font-size: 1.6rem, $color: $menu-accent, $font-weight: $bold);
    align-items: flex-start;
  }

  & > div:first-of-type {
    margin-bottom: 2rem;
  }
}

.generalLabels {
  label {
    @include font($font-size: 1.4rem, $font-weight: $bold, $color: $default-color);
    align-items: flex-start;
  }
}

.alignCenterOnMobile {
  @include mobileLarge {
    margin: 4rem auto 0;
    display: flex;
  }
}

.alignCenterOnDesktop {
  @include mobileLarge {
    @include position(relative, null, null, 0, null);
    margin-top: 4rem;
  }
  @include position(absolute, null, null, 12rem, 0);
  width: 100%;
  text-align: center;

  button {
    @include tabletLandscape {
      margin-left: 1rem;
    }
    @include mobileLarge {
      margin-left: 0;
    }

    margin-left: 12rem;
  }
}

.contentWrapper {
  margin-top: 5rem;
  font-size: 1.7rem;
  color: $default-color;
}

.section {
  margin-top: 3rem;
}

.centerSectionWrapper {
  @extend .verticalCenterColumn;
}

.buttonWrapper {
  @include mobileLarge {
    flex-direction: column;
  }

  @extend .totalCenter;
  margin-top: 3rem;
  flex-wrap: wrap;

  button {
    height: 3.2rem;
  }

  button + button {
    @include mobileLarge {
      margin: 1.5rem 0 2rem 0;
    }

    margin-left: 6rem;
  }
}

.uploadFileList {
  @include font($font-size: 1.4rem, $font-weight: $bold, $color: $menu-accent);
  margin-top: 2rem;
}

.title {
  @include font($font-size: 1.6rem, $color: $menu-accent, $font-weight: $bold);

  &Large {
    font-size: 3rem;
    margin-bottom: 0;
  }

  &.defaultTitle {
    color: $default-color;
    margin-right: auto;
  }

  &:not(.titleLarge) {
    margin-bottom: 3rem;
  }
}

.titleSection {
  @include mobileLarge {
    text-align: center;
  }
  @include display($display: flex, $flex-direction: column, $align-items: center);
}

.alertText {
  @include font($font-size: 1.4rem, $color: $default-color, $font-weight: $light);
  margin-top: 4rem;

  &:before {
    @include font($color: $alert, $font-weight: $regular);
    content: '* ';
    vertical-align: super;
  }
}

.mandatoryText {
  @include font($font-size: 1.2rem, $color: $alert, $font-weight: $light);
  margin: 2rem 0 6rem;
}

.centerSection {
  @extend .verticalCenter;
  @extend .resetToDirectionColumn;
  @include size($max-width: 80rem, $width: 100%);
  margin: 6rem auto 0;
}

.content {
  @include font($font-size: 1.6rem, $text-align: center, $color: $default-color);
  margin-top: 5rem;
  width: 100%;
}

.textSection {
  margin-top: 3rem;
}

.buttonSection {
  @include display($display: flex, $flex-direction: column);

  button {
    @include mobileMedium {
      font-size: 1.4rem;
    }

    @include size($max-width: 23rem, $width: 100%);
    margin: 0 auto;
  }

  button + button {
    margin-top: 2rem;
  }
}

.personaliseProgramWrapper {
  @include tabletLandscape {
    flex-direction: column;
  }
  display: flex;

  form {
    @include tabletLandscape {
      margin: 0 auto;
    }
    @include size($max-width: 41.8rem, $width: 100%);

    .alignCenterOnDesktop {
      @include tabletLandscape {
        @include position(relative, null, null, 0, null);
  
        button {
          margin-left: 0;
        }
      }
    }
  }

  .budgetInformation {
    @include tabletLandscape {
      margin-left: 0;
      margin-top: 3rem;
    }
    max-width: 41.8rem;
    margin-left: 10rem;

    &Box {
      border: 0.1rem solid $border-dark;
      padding: 1.5rem 1rem 0.5rem;
    }

    &Optional {
      @include font($color: $border-dark, $font-size: 1rem);
      padding-bottom: 2rem;
    }

    &Title {
      @include font($color: $accent-color, $font-size: 1.3rem);
      padding-bottom: 2rem;
      min-height: 15rem;
    }

    &SubTitle {
      @include font($color: $accent-color, $font-size: 1.4rem, $font-weight: $bold);
      padding-bottom: 0.5rem;
    }

    &Value {
      @include font($color: $border-dark, $font-size: 1.2rem, $font-weight: $bold);
      padding-bottom: 2rem;
    }
  }

  .buttonMargin {
    margin-top: 3rem;
  }

  .submitButtonContainer {
    pointer-events: none;
    * {
      pointer-events: auto;
    }
  }
}
