@import '../../imports';
@import '../../utils/constants';

.wrapper {
  @include size($width: 100%, $max-width: 82.6rem);
  margin: 5rem auto 0;

  @include mobileLarge {
    margin-top: 2rem;
  }
}

.block {
  @include prefix(transition, all 0.2s ease-in-out, webkit moz ms o);
  border-radius: 0 0 1rem 1rem;
  padding: 4.4rem 6rem;
  opacity: 0.35;

  @include mobileLarge {
    padding: 2rem 3rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 1;
  }

  &Disabled {
    @include buttonStyle($primary-color, transparent);
    opacity: .35!important;
    cursor: not-allowed;
  }

  button {
    @include buttonStyle($primary-color, transparent);
    margin-left: auto;
    border: 0.1rem solid $primary-color;

    @include mobileLarge {
      @include buttonStyle($forth-color, $gradient);
      margin-left: 0;
      margin-top: 1.5rem;
      opacity: 1;
    }
  }

  &:hover {
    button {
      @include buttonStyle($forth-color, $gradient);
    }

    color: $menu-accent;
    opacity: 1;

    svg {
      color: $menu-accent;
    }

  }

  svg {
    @include font($font-size: 4.7rem, $color: $primary-color);
    @include prefix(transition, all 0.2s ease-in-out, webkit moz ms o);
    margin-right: 4rem;

    @include mobileLarge {
      font-size: 2.6rem;
      margin: 0 0 0.8rem;
    }
  }
}

.title {
  @include font($font-size: 2rem, $font-weight: $bold);

  @include mobileLarge {
    font-size: 1.6rem;
    text-align: center;
  }
}
