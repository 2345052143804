@import "../../imports";

.dashboard {

  &ListLoading{
    padding-top: 5rem;
  }

  &Kpis {
    @include display-flex();
    background-color: $default-color;
  }

  &Kpi {
    &Title {
      @include respond-to-max('mobile-large') {
        @include font($font-size: 1.4rem);
      }
      @include font($font-size: 1.8rem, $font-weight: map_get($font-weights, 'bold'));
    }

    &Width {
      @include size($min-width: 33.3%, $max-width: 33.3%);
    }

    @include size($width: 100%, $min-width: 25%, $max-width: 25%);
    padding: 3rem 0 3rem 3rem;
    word-break: break-word;
    cursor: pointer;
    @include respond-to-max('mobile-large') {
      padding: 2rem .5rem;
      @include font($font-size: 1.4rem);
    }

    h3 {
      font-size: 3.2rem;
      color: inherit;
      margin: 0;
      @include respond-to-max('mobile-large') {
        @include font($font-size: 1.4rem);
      }

      p {
        @include respond-to-max('mobile-large') {
          @include font($font-size: 1.4rem);
        }
      }
    }
  }

  &Filters {
    flex-wrap: wrap;
    @include respond-to-max('mobile-large'){
      padding: 1rem 3rem !important;
      text-align: center;
    }

    &Wrapper {
      display: flex;
      @include respond-to-max('mobile-large') {
        flex-direction: column;
        flex-wrap: wrap;
      }
    }

    &Date {
      @include size($width: 18.5rem);
      @include font($text-align: center);
      padding: 1rem;
      border-radius: 3rem;
      border: .1rem solid $border-color;
      cursor: pointer;
    }

    &To{
      padding: 1rem;
      @include font($color: $default-text-color, $font-weight: map_get($font-weights, 'regular'));
    }

    &Reset {
      @include font($color: $default-text-color);
      background: transparent;
      padding: 0;
      margin: 0 -6rem 0 1rem;

      @include respond-to-max('mobile-large') {
        margin: 1rem 0 0 0;
      }

      @include respond-to-max('desktop-medium') {
        margin: 0 0 0 1rem;
      }

      &Wrapper {
        display: flex;
        @include respond-to-max('mobile-large') {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }
    }

    &Sort{
      text-align: center;
      margin-bottom: 1rem;
      flex: 0 0 100%;
    }

    &Loading {
      @include font($color: $default-color);
    }
  }
}

.goalsStyle {
  display: flex;
  justify-content: flex-start; // Align products horizontally
  gap: 40px; // Add space between each product
  margin-top: 30px;
  margin-bottom: 30px;

  .productItem {
    display: flex;
    flex-direction: column; // Stack name above image
    align-items: center; // Center-align name and image
    text-align: center; // Ensure text is centered

    .productName {
      margin-bottom: 10px; // Space between name and image
      font-weight: bold;
    }

    .productImage {
      width: 120px; // Set a fixed width for the images
      height: auto; // Maintain the aspect ratio
      object-fit: contain; // Prevent cropping of images
      max-height: 120px; // Set a maximum height
      cursor: 'pointer';
    }
  }
}

