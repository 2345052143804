@import "../../../imports";

$bannerHeight: 30rem;

.banner{
  @include size($max-width: 100%, $max-height: $bannerHeight,$width:100%);
  @include position($position: fixed, $top: 0);
  overflow: hidden;
  z-index: 0;
  img{
    width: 100%;
    opacity: .8;
    filter: blur(.1rem);
  }
  &After{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: $bannerHeight;
    top: 0;
    right: 0;
  }
}
