.card {
  background: $default-color;
  border-radius: 1rem;

  &Title {
    @extend .titles-base;

    &Small {
      font-size: 1.7rem;
    }

    &Lg {
      font-size: 3rem;
    }
  }
}
