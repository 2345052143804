@each $key, $value in $positionValues {
  .top#{$key} {
    top: $value;
  }

  .left#{$key} {
    left: $value;
  }

  .right#{$key} {
    right: $value;
  }

  .bottom#{$key} {
    bottom: $value;
  }
}

@each $media, $breakpoint in $breakpoints-max-sizes {
  @media (max-width: $breakpoint) {
    @each $key, $value in $positionValues {
      .#{$media}Top#{$key} {
        top: $value;
      }

      .#{$media}Left#{$key} {
        left: $value;
      }

      .#{$media}Right#{$key} {
        right: $value;
      }

      .#{$media}Bottom#{$key} {
        bottom: $value;
      }
    }
  }
}

@each $key, $value in $position {
  .#{$key} {
    position: $value !important;
  }
}
